// pioLogger.js
import pino from 'pino'; 

// Define a default logger configuration (for development)
const defaultLoggerConfig = {
  transport: {
    target: 'pino-pretty',
    options: {
      colorize: true
    },
    browser: {
      serialize: true,
      asObject: true,
    }
  }
};

// Define a production logger configuration (for production)
const productionLoggerConfig = {
  level: 'silent', // Set the logging level to quiet in production
};

const isProduction = process.env.NODE_ENV === 'production';
const logger = pino(isProduction ? productionLoggerConfig : defaultLoggerConfig);
export { logger as default }