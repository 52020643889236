// httpService.js
import axios from 'axios';
import { setupAuthInterceptor } from './authInterceptor';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

// Function to initialize httpClient
const initializeHttpClient = () => {
  setupAuthInterceptor(httpClient);
};

export default httpClient;
export { initializeHttpClient };
