<template>
  <div v-if="shouldDisplayMap">
    <label id="listbox-label" class="ml-2 block text-sm font-medium leading-6 text-gray-900">
      Location
    </label>
    <!-- <img :src="staticMapUrl" alt="Static Map" class="w-full h-auto rounded-md mb-3" /> -->
    <div class="w-full h-full">
      <div ref="mapContainer" class="w-72 h-72 rounded-md w-full"></div>
      <button v-if="!commonStore.isReadOnly()" :disabled="buttonDisabled" @click="requestGpsLocation"
        class="mt-2 text-white font-bold py-2 px-4 rounded bg-indigo-600 hover:bg-indigo-500  w-full"
        :class="buttonDisabled ? 'bg-gray-400 animate-pulse' : 'bg-indigo-500'">


        {{ buttonDisabled ? 'Capturing GPS Location...' : 'Update Current Location' }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useCommonStore } from '@/stores/commonStore'; // Adjust the import path as needed
import mapboxgl from 'mapbox-gl';

const mapContainer = ref(null);
const buttonDisabled = ref(false); // Button is disabled initially
const commonStore = useCommonStore();


let map; // Define map variable outside to access it globally in this setup
let marker; // Define marker variable for updating its position

commonStore;


//request-gps-location

onMounted(async () => {
  if (shouldDisplayMap.value) {
    initializeMap();
  }
});

// Watch for changes in the decomposedPosition

const shouldDisplayMap = computed(() => {
  // Check if the map should not be displayed because it's at the default position
  const isDefaultPosition = decomposedPosition.value.lat === 0 && decomposedPosition.value.lng === 0;
  // Check if the commonStore indicates the component is in read-only mode
  const isReadOnlyMode = commonStore.isReadOnly();
  if(!isReadOnlyMode) return true;

  // Return true if we're in read-only mode and not at the default position, otherwise false
  return !isDefaultPosition;
});

function requestGpsLocation() {
  buttonDisabled.value = true;
  mapContainer.value.dispatchEvent(new CustomEvent('request-gps-location', {
    bubbles: true,
  }));
}

document.addEventListener('gps-location-finish-loading', resetButtonState);

function resetButtonState() {
  buttonDisabled.value = false; // Enable the button when 'gps-location-finish-loading' event is fired
}

const props = defineProps({
  position: {
    type: String,
    required: true,
    default: '0,0',
  },

  zoom: {
    type: Number,
    default: 17,
  },
  width: {
    type: Number,
    default: 600,
  },
  height: {
    type: Number,
    default: 400,
  },
});

const accessToken = 'pk.eyJ1IjoiamFrdWItZ2V0dGhpbmd5IiwiYSI6ImNsdGhtb3N5bjA2dDcyanFxMWFweTRsMnIifQ.2XNY-aaB-cRr6QV7fHJrOA'; // Replace with your Mapbox access token

const decomposedPosition = computed(() => {
  const parts = props.position.split(',');
  return {
    lat: parseFloat(parts[0]),
    lng: parseFloat(parts[1]),
  };
});

function initializeMap() {
  console.log('setting up map');

  mapboxgl.accessToken = accessToken;
  let { lng, lat } = decomposedPosition.value;
  let zoom = props.zoom;

  if (lng === 0 && lat === 0) {
    lng = -98.5795; // Center longitude of USA
    lat = 39.8283; // Center latitude of USA
    zoom = 3; // Zoom out to show the entire USA
  }

  map = new mapboxgl.Map({
    container: mapContainer.value, // container ref
    style: 'mapbox://styles/mapbox/standard', // style URL
    center: [lng, lat], // corrected order: [longitude, latitude]
    zoom: zoom, // using the zoom level from props
    pitch: 50, // pitch in degrees
  });

  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true,
    showUserHeading: true
  }));

  const el = document.createElement('div');
  el.className = 'isometric-cube';

  // Add a marker to the map
//crane_5738902

  marker = new mapboxgl.Marker()
    .setLngLat([lng, lat])
    .addTo(map);


  function onDragEnd() {
    const lngLat = marker.getLngLat();

    console.log(lngLat);
    // dispatch updated coordinates
  }

  marker.on('dragend', onDragEnd);

}

watch(decomposedPosition, (newVal) => {
  if (map) {

    let { lat, lng } = newVal;
    let zoom = props.zoom;

    if (lng === 0 && lat === 0) {
      lng = -98.5795; // Center longitude of USA
      lat = 39.8283; // Center latitude of USA
      zoom = 3;
    }

    map.flyTo({
      center: [lng, lat],
      essential: true,
      zoom: zoom
    });
    marker.setLngLat([lng, lat]);
  }
});

</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

.isometric-cube {
  background-size: cover;
}

.cube-face {
  position: absolute;
  width: 10px;
  height: 10px;
}

.front {
  background-size: cover;
}

.side {
  background: #f00;
  transform: rotateY(90deg) translateX(50%);
}

.top {
  background: #f00;
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: bottom;
}
</style>