<template>
  <div
    class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
    <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="toggleSidebar">
      <span class="sr-only">Open sidebar</span>
      <Bars3Icon class="h-6 w-6" aria-hidden="true" />
    </button>

    <!-- Separator -->
    <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

    <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <!-- Search form -->
      <div class="relative flex flex-1">
        <!-- empty div to be later replaced by search-->
      </div>
      <!-- <form class="relative flex flex-1" action="#" method="GET">
          <label for="search-field" class="sr-only">Search</label>
          <MagnifyingGlassIcon class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" aria-hidden="true" />
          <input id="search-field" class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." type="search" name="search" />
        </form> -->

      <!-- Icons and User Menu -->
      <div class="flex items-center gap-x-4 lg:gap-x-6">

       
        <div>
          <router-link :to="{ name: 'subscribe' }"
            class="inline-flex items-center rounded-full bg-purple-50 px-4 py-2 text-sm font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
            <!-- Free Trial - {{ customerStore.subscription.daysLeft }} {{ customerStore.subscription.daysLeft === 1 ? 'day' : 'days' }} left -->
            Upgrade Subscription
        </router-link>
        </div>


        <!-- <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button> -->

        <!-- Separator -->
        <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

        <!-- Profile dropdown -->
        <Menu as="div" class="relative">
          <MenuButton class="-m-1.5 flex items-center p-1.5">
            <span class="sr-only">Open user menu</span>
            <img class="h-8 w-8 rounded-full bg-gray-50" :src="userPhotoUrl || '/assets/avatar-placeholder.jpg'" alt="" />
            <span class="hidden lg:flex lg:items-center">
              <!-- <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{{ userName }}</span> -->
              <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }"
                @click="handleNavigation(item)">
              <a :href="item.href"
                :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">{{
                  item.name }}</a> </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </div>
</template>
  

<script setup>
import { computed } from 'vue';
import { auth0 } from '@/auth';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { useCustomerStore } from '@/stores/customerStore';
import router from '@/router/router';

import {
  Bars3Icon,
  // BellIcon,
  ChevronDownIcon,
  // MagnifyingGlassIcon,
} from '@heroicons/vue/20/solid';


const userNavigation = [
  { name: 'Billing', action: 'getBillingLink' },
  { name: 'Support', action: 'getSupportLink' },
  { name: 'Logout', routeName: 'logout' }
];

const customerStore = useCustomerStore();
const user = computed(() => auth0.user.value);
// const userName = computed(() => user.value ? user.value.name : null);
const userPhotoUrl = computed(() => user.value ? user.value.picture : null);

// Define props
defineProps({
  toggleSidebar: Function,
});


async function handleNavigation(item) {
  if (item.action && item.action === 'getBillingLink') {
    const billingUrl = await customerStore.getStripeBillingLink();
    if (billingUrl) window.location.href = billingUrl; // Redirect to the billing URL
  }
   else if (item.action && item.action === 'getSupportLink') {
    window.open('https://getthingy.io/support', '_blank');

  } else if (item.routeName) {
    // Handle regular routing for other navigation items
    router.push({ name: item.routeName });
  }
}

</script>

