<template>
  <div v-if="thingsStore.isThingLoading">
      <PageLoader></PageLoader>
    </div>
  <div v-else>
    <ThingyDetails v-if="thingsStore.thing" :isReadOnly="isReadOnly"></ThingyDetails>
  </div>
</template>
<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import ThingyDetails from '@/components/ThingyDetails.vue';
import { useThingsStore } from '@/stores/thingsStore';
import { useCommonStore } from '@/stores/commonStore'; // Adjust the import path as needed
import PageLoader from '@/components/PageLoader.vue';

const thingsStore = useThingsStore();
const route = useRoute();
const isReadOnly = true;
const commonStore = useCommonStore();



onMounted(() => {
  commonStore.setReadOnly(true);
  const thingId = route.params.thingId;
  if (thingId) {
    thingsStore.fetchThingById(thingId);
  }
});


</script>