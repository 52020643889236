<template>
  <lr-file-uploader-regular
    class="my-locale-override"
    css-src="https://cdn.jsdelivr.net/npm/@uploadcare/blocks@0.30.5/web/lr-file-uploader-regular.min.css"
    :ctx-name="uniqueCtxName"
    />

  <lr-config 
    :ctx-name="uniqueCtxName"
    pubkey="5f7de160ebd3ed758bb0" 
    maxLocalFileSizeBytes="10000000" 
    :imgOnly="props.imgOnly"
    multiple="false" 
    sourceList="local, camera, url, gdrive, dropbox, onedrive, box" 
    useCloudImageEditor="true" 
    :cropPreset="props.cropRatio"
    style="border:0; display:none;"
    removeCopyright="true"
    />

  <lr-data-output 
    :ctx-name="uniqueCtxName"
    use-input 
    use-event
  />

  <lr-upload-ctx-provider 
    ref="uploaderProvider"
    :ctx-name="uniqueCtxName"
    @upload-finish="handleUploadFinished"
    @done-flow="handleDoneFlow"
    @cloud-modification="handleCloudModification"
  />

</template>
  
<script setup>
import { ref, computed } from 'vue';
import logger from '@/utils/logger.mjs'

const uploadedUiid = ref(null);
const uploaderProvider = ref(null);
const uniqueCtxName = computed(() => `ctx-${Date.now()}-${Math.floor(Math.random() * 1000)}`);

const emit = defineEmits(['uploaded']);

const props = defineProps({
  field: String,
  cropRatio: {
    type: String,
    required: false
  },
  imgOnly: {
    type: Boolean,
    required: false,
    default: false
  },
});


const handleCloudModification = (e) => {
  logger.info('cloud modfied');
  logger.debug(e);

  const event = {};

  event.path = props.field;
  event.value = "https://ucarecdn.com/" + uploadedUiid.value +'/' + e.detail;

  emit('uploaded',event);
  
}

const handleUploadFinished = (e) => {
  logger.info('upload finished');

  const event = {};
  event.path = props.field;
  event.value = e.detail[0].cdnUrl;

  uploadedUiid.value = e.detail[0].uuid;
  logger.debug(event);
  emit('uploaded',event);

  //  if (uploaderProvider.value && uploaderProvider.value.uploadCollection.size === 1) {
  //    const entryId = uploaderProvider.value.uploadCollection.items()[0];
  //    const entry = uploaderProvider.value.uploadCollection.read(entryId);
  //    uploaderProvider.value.$["*focusedEntry"] = entry;
  //    uploaderProvider.value.$["*currentActivity"] = LR.ActivityBlock.activities.CLOUD_IMG_EDIT;
  //  }
 }


const handleDoneFlow = (e) => {
  logger.info('update flow is done');
  logger.debug(e);

  if (uploaderProvider.value && uploaderProvider.value.uploadCollection) {
    uploaderProvider.value.uploadCollection.clearAll();
  }
}

</script>
  
<style >
.my-config {
  --darkmode: 0;
  --h-accent: 210;
  --s-accent: 100%;
  --l-accent: 50%;
}

.my-locale-override {
  --l10n-upload-files: 'Choose Stuff';
}
</style>
  