<template>
  <div ref="dropdownContainer">


    <label id="listbox-label" class="ml-2 block text-sm font-medium leading-6 text-gray-900">{{ fieldLabel }}</label>

    <!-- Displaying when not activated -->
    <button v-show="!showDropdown" type="button" @click="toggleDropdown"
      :class="[isReadOnly ? 'relative w-full p-2 rounded cursor-text py-1.5 pl-2 pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6' : 'hover:bg-thing-edit relative w-full p-2 rounded cursor-text py-1.5 pl-2 pr-10 text-left text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6']"
      aria-haspopup="listbox" :aria-expanded="showDropdown.toString()" aria-labelledby="listbox-label">

      <span class="inline-flex w-full items-center truncate">
        <img v-if="selectedUser" :src="selectedUser.imageUrl || '/assets/avatar-placeholder.jpg'" alt=""
          class="mr-2 h-6 w-6 flex-shrink-0 rounded-full" />
        <div v-if="!iconOnly">
          <span v-if="isReadOnly" class="truncate">{{ selectedUser ? selectedUser.name : '-' }}</span>
          <span v-else class="truncate">{{ selectedUser ? selectedUser.name : 'Select a user' }}</span>
      </div>
      </span>
    </button>



    <!-- activated dropdown -->
    <div class="relative">
      <!-- Input Field (shown when dropdown is active) -->
      <input ref="searchInput" v-show="showDropdown" id="combobox" type="text"
        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        role="combobox" aria-controls="options" aria-expanded="false" @input="updateSearch" v-model="searchQuery">
      <!-- Dropdown List -->
      <ul v-show="showDropdown"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1" role="listbox" aria-labelledby="listbox-label">
        <li v-for="(user, index) in filteredPeople" :key="user.id" @click="selectUser(user)"
          @mouseover="hoveredIndex = index"
          :class="['text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 mt-1', hoveredIndex === index ? 'bg-indigo-600 text-white' : '']"
          role="option">
          <div class="flex items-center">
            <img :src="user.imageUrl || '/assets/avatar-placeholder.jpg'" alt=""
              class="h-6 w-6 flex-shrink-0 rounded-full" />
            <span
              :class="['pl-2 font-normal truncate', selectedUser && user.id === selectedUser.id ? 'font-semibold' : '']">{{
                user.name }}</span>
          </div>
          <span v-if="selectedUser && user.id === selectedUser.id"
            class="text-indigo-600 absolute inset-y-0 right-0 pl-3 flex items-center pr-4">
            <CheckIcon />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, onUnmounted, computed, nextTick, watch } from 'vue';
import { usePeopleStore } from '@/stores/peopleStore';
import { CheckIcon } from '@heroicons/vue/20/solid'


const emit = defineEmits(['update-assigned-owner']);

const props = defineProps({
  selectedUserId: String,
  fieldName: String,
  fieldLabel: String,
  textClass: String,
  inputClass: String,
  customSaveFunction: Function,
  isReadOnly: Boolean,
  iconOnly: Boolean
});

const peopleStore = usePeopleStore();
const showDropdown = ref(false);
const searchQuery = ref('');
const hoveredIndex = ref(null);
const dropdownContainer = ref(null);
const searchInput = ref(null);
let selectedUser = ref(null);

const toggleDropdown = () => {

  if (!props.isReadOnly) {
    showDropdown.value = !showDropdown.value;
    if (showDropdown.value) {
      nextTick(() => {
        searchInput.value.focus();
      });
    }
  }
};

const selectUser = (user) => {
  selectedUser.value = user;
  showDropdown.value = false;
  peopleStore.resetSearchQuery();

  emit('update-assigned-owner', user);
};

watch(() => props.selectedUserId, (userId) => {
  (async () => {
    try {
      selectedUser.value = await peopleStore.fetchPersonById(userId);
    } catch (error) {
      console.error('Error fetching person:', error);
      // Handle the error appropriately
    }
  })();
}, { deep: true });

const updateSearch = () => {
  peopleStore.setSearchQuery(searchQuery.value);
};


const filteredPeople = computed(() => peopleStore.filteredPeople);

const handleClickOutside = (event) => {
  if (dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
    showDropdown.value = false;
    peopleStore.resetSearchQuery();
  }
};

onMounted(async () => {
  //await peopleStore.fetchPeople();
  selectedUser.value = await peopleStore.fetchPersonById(props.selectedUserId);
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

</script>
