import { createAuth0, createAuthGuard, useAuth0 } from '@auth0/auth0-vue';

export const auth0 = createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    }
  },
  {
   // skipRedirectCallback: true
  });

  export const authGuard = createAuthGuard();
  export const logout = auth0.logout;
  export const isAuthenticated = auth0.isAuthenticated;
  export const isLoading = useAuth0();

  export async function getToken() {
    if (auth0.user || auth0.user.value) {
      try {
        return await auth0.getAccessTokenSilently();
      } catch (error) {
        return null;
      }
    }
    return null;
  }
