class CustomHtmlEmbed {
    static get toolbox() {
      return {
        title: 'Embed URL',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" /></svg>',
      };
    }
  
    constructor({ data, api, readOnly, config }) {
        this.api = api;
      this.readOnly = readOnly;
      this.data = {
        html: data.html || '',
        url: data.url || '',
      };
      this.config = config || {}; // Store config passed to the constructor
      this.container = null;
      this.urlInput = null;
    }
    // Indicating that the plugin supports read-only mode
  static get isReadOnlySupported() {
    return true;
  }

  
    static get pasteConfig() {
      return {
        patterns: {
          link: /^(https?:\/\/\S+)/,
        },
      };
    }
  
    render() {
        this.container = document.createElement('div');
        this.container.classList.add('html-embed-container');
      
        if (!this.readOnly) {
          // Input Container
          const inputContainer = document.createElement('div');
          inputContainer.className = "mt-4 mb-2 flex rounded-md shadow-sm mb-0";
      
          // URL Input
          this.urlInput = document.createElement('input');
          this.urlInput.type = 'text';
          this.urlInput.name = "embedUrl";
          this.urlInput.id = "embedUrl";
          this.urlInput.className ='w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
          this.urlInput.placeholder = 'Enter URL';
          this.urlInput.value = this.data.url;
          this.urlInput.readOnly = this.readOnly;
      
          // Embed Button
          const embedButton = document.createElement('button');
          embedButton.textContent = 'Embed';
          embedButton.type = 'button';
          embedButton.className = 'relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset bg-indigo-600 ring-indigo-300 hover:bg-indigo-700';
          embedButton.addEventListener('click', () => this.embedContent(this.urlInput.value));
      
          // Append elements
          inputContainer.appendChild(this.urlInput);
          inputContainer.appendChild(embedButton); // Append button next to the input
          this.container.appendChild(inputContainer);

          // add listeners
          this.urlInput.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
              event.preventDefault(); // Prevent the default action to avoid submitting the form or any other default behavior
              event.stopPropagation(); // Prevent event from bubbling up to Editor.js handlers

              this.embedContent(this.urlInput.value);
            }
          });
        }
      
        // Display embedded HTML if available
        if (this.data.html) {
          this.displayEmbeddedHtml(this.data.html);
        }
      
        return this.container;
      }
      
      displayError(message) {
        // Remove any existing error message first
        const existingError = this.container.querySelector('.error-message');
        if (existingError) {
          this.container.removeChild(existingError);
        }
      
        const errorDiv = document.createElement('div');
        errorDiv.className = "rounded-md bg-red-50 p-4 error-message";
        errorDiv.innerHTML = `
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Include your XCircle icon here -->
              <svg class="h-5 w-5 text-red-400" aria-hidden="true"><!-- XCircleIcon SVG here --></svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">${message}</h3>
            </div>
          </div>
        `;
      
        this.container.appendChild(errorDiv);
      }
  
    onPaste(event) {
      const { key, data } = event.detail;
  
      if (key === 'link') {
        this.embedContent(data);
        this.urlInput.value = data; // Set the pasted URL into the input field
      }
    }

    removeExistingContent() {
      const existingEmbed = this.container.querySelector('.html-embed-content');
      if (existingEmbed) {
        this.container.removeChild(existingEmbed);
      }
    }
  
    async embedContent(url) {
      this.clearError(); // Clear any existing error messages
      this.removeExistingContent(); // Add this line to remove existing embedded content

      this.data.url = url; // Update the URL in the data object
      const bearerToken = this.config.token;

      console.log(this.config);
      const encodedUrl = encodeURIComponent(url);
      const iframelyUrl = `https://api.getthingy.io/api/iframely?url=${encodedUrl}`;
  
      try {
        const response = await fetch(iframelyUrl, {
            method: 'GET', 
            headers: {
              'Authorization': `Bearer ${bearerToken}`, 
              'Content-Type': 'application/json'
            }
          });

        if (!response.ok) {
          this.displayError("This content cannot be embedded.");
          return;
        }
        
        const data = await response.json();
        this.data.html = data.html;
        this.displayEmbeddedHtml(data.html);
      } catch (error) {
        console.error('Error embedding content:', error);
        // Optionally handle the error, e.g., show an error message
      }
    }

    clearError() {
      const existingError = this.container.querySelector('.error-message');
      if (existingError) {
        this.container.removeChild(existingError);
      }
    }
  
    displayEmbeddedHtml(html) {
      // Remove any existing embedded content
      const existingEmbed = this.container.querySelector('.html-embed-content');
      if (existingEmbed) {
        this.container.removeChild(existingEmbed);
      }
  
      const template = document.createElement('template');
      template.innerHTML = html;
      template.content.firstChild.classList.add('html-embed-content', 'iframely-shadow', 'mb-4','rounded-md');
      this.container.appendChild(template.content.firstChild);
    }
  
    save() {
      return {
        html: this.data.html,
        url: this.data.url,
      };
    }
  
    validate() {
      // You can add validation logic here
      return true;
    }
  }

  
  
  export default CustomHtmlEmbed;
  