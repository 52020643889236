// stores/commonStore.js
import { defineStore } from 'pinia';
// import { auth0 } from '@/auth'

export const useCommonStore = defineStore('common', {
  state: () => ({
    headless: false,
    readOnly: false,
    isAuthenticated: false,
  }),

  actions: {
    setHeadless(value) {
      this.headless = value;
    },
    setReadOnly(value) {
      this.readOnly = value;
    },
    isReadOnly() {
      return this.readOnly;
    }
  },
});
