<template>
  <div v-if="!isReadOnly">
    <label v-if="fieldLabel" id="listbox-label" class="ml-2 block text-sm font-medium leading-6 text-gray-900">{{
      fieldLabel
    }}</label>
    <div class="relative w-full -mt-1">
      <input v-if="isEditMode" v-model="currentValue" ref="inputRef" @blur="saveChanges" @keyup.enter="saveChanges"
        :class="[inputClass, 'bg-transparent border-0 p-1 rounded w-full']" role="textbox" />

      <span v-if="!isEditMode && currentValue"
        :class="[textClass, 'hover:bg-thing-edit p-2 block rounded cursor-text w-full']" @click="enableEditMode"
        role="button" aria-label="Edit"> {{ currentValue }}
      </span>

      <span v-if="!isEditMode && (currentValue == '' || !currentValue)"
        :class="[textClass, 'p-2 bg-thing-edit-light hover:bg-thing-edit text-gray-400 block rounded cursor-text w-full']"
        @click="enableEditMode" role="button" aria-label="Edit"> {{ props.placeholder || "Enter content" }}
      </span>
    </div>
  </div>

  <div v-else>
    <div v-if="currentValue !== '' && currentValue != null">
      <label v-if="fieldLabel" id="listbox-label" class="ml-2 block text-sm font-medium leading-6 text-gray-900">{{
        fieldLabel
      }}</label>
      <span :class="[textClass, 'p-2 block rounded cursor-text w-full']" role="button"> {{ currentValue || 'n/a' }}
      </span>
    </div>
  </div>
</template>
 
<script setup>

import { ref, watch, nextTick } from 'vue';
import logger from '@/utils/logger.mjs';

const props = defineProps({
  value: String,
  fieldId: String,
  fieldLabel: String,
  fieldPath: String,
  isReadOnly: Boolean,
  textClass: String,
  inputClass: String,
  placeholder: String,
});

const isEditMode = ref(false);
const currentValue = ref(props.value);
const inputRef = ref(null);
const isSaving = ref(false);

const emit = defineEmits(['update-field'])


watch(() => props.value, (newValue) => {
  currentValue.value = newValue;
});

const enableEditMode = () => {
  if (!props.isReadOnly) {
    isEditMode.value = true;
    nextTick(() => {
      inputRef.value.focus();
    });
  }
};

const saveChanges = () => {

  // to prevent any double accidental updates
  if (isSaving.value) {
    return;
  }
  isSaving.value = true;
  emit('update-field', { id: props.fieldId, value: currentValue.value, path: props.fieldPath });
  logger.info('emitted update-field');
  isEditMode.value = false;
  setTimeout(() => {
    isSaving.value = false;
  }, 100);
};


</script>
 