<template>
  <div id="thingy-body" class="pb-4">
      <div v-if="loadingStore.isLoading || (!isAuthenticated && !isPublicRoute)">
        <PageLoader></PageLoader>
      </div>
  <div v-else>
    <NavbarComponent v-if="!commonStore.headless" :sidebarOpen="sidebarOpen" @update:sidebarOpen="handleSidebarUpdate"/>
    
    <div :class="!commonStore.headless ? 'lg:pl-20' : ''">
      <TopBarComponent v-if="!commonStore.headless" :toggleSidebar="toggleSidebar" />
      <RouterView class="mb-10"></RouterView>
    </div>
  </div>
  <DialogComponent/>
  </div>
</template>

<script setup>

import { ref, computed, watch, onMounted } from 'vue';
import NavbarComponent from './components/NavbarComponent.vue';
import TopBarComponent from './components/TopBarComponent.vue';
import DialogComponent from '@/components/DialogComponent.vue';
import PageLoader from '@/components/PageLoader.vue';
import { useRoute} from 'vue-router';
import { isAuthenticated } from '@/auth';
import { useLoadingStore } from '@/stores/loadingStore';
import { useCommonStore } from '@/stores/commonStore'; 
import { auth0 } from '@/auth';
import { useCustomerStore } from './stores/customerStore';
import { useUserAnalytics } from '@/analytics/useUserAnalytics.mjs';
import logger from '@/utils/logger.mjs';




const sidebarOpen = ref(false);

const commonStore = useCommonStore();
const customerStore = useCustomerStore();
const analytics = useUserAnalytics();
const route = useRoute();
const loadingStore = useLoadingStore();
const isPublicRoute = computed(() => route.meta.public === true);

function handleSidebarUpdate(newVal) {
  sidebarOpen.value = newVal;
}

onMounted(() => {
  analytics.trackPageView(window.location.pathname);
});

function toggleSidebar() {
  sidebarOpen.value = !sidebarOpen.value;
}

// Watch for changes in the auth0.user object
watch(() => auth0.user.value, (newUser) => {
  const customerId = newUser?.customerId;
  analytics.identify(newUser);

  if (customerId) {
    logger.log('Auth0 user updated, fetching customer data');
    customerStore.fetchCustomer();
    // if expired subscription, redirect to subscribe page

  }
}, { deep: true }); // Use { deep: true } if the object's nested properties might change and you want to detect that.



</script>

<style>
/* Global styles */
</style>
