
 


  <template>
    <div>
      <p>
        Track function:
        <select v-model="selected">
          <option
            v-for="option in options"
            :key="option.text"
            :value="option"
          >
            {{ option.text }}
          </option>
        </select>
      </p>
  
      <qrcode-stream
        :track="selected.value"
        @error="logErrors"
      />
    </div>
  </template>
  
  <script>
  import { QrcodeStream } from 'vue-qrcode-reader';
  import { useThingsStore } from '@/stores/thingsStore'; // Adjust the path to your store
  import logger from '@/utils/logger.mjs';
  
  export default {
    components: { QrcodeStream },
  
    data() {
      const thingsStore = useThingsStore();
      const options = [
        { text: 'nothing (default)', value: undefined },
        { text: 'outline', value: this.paintOutline },
        { text: 'centered text', value: this.paintCenterText },
        { text: 'bounding box', value: this.paintBoundingBox }
      ]
  
      const selected = options[2]
  
      return { selected, options, thingsStore}
    },
  
    methods: {
      paintOutline(detectedCodes, ctx) {
        for (const detectedCode of detectedCodes) {
          const [firstPoint, ...otherPoints] = detectedCode.cornerPoints
  
          ctx.strokeStyle = 'red'
  
          ctx.beginPath()
          ctx.moveTo(firstPoint.x, firstPoint.y)
          for (const { x, y } of otherPoints) {
            ctx.lineTo(x, y)
          }
          ctx.lineTo(firstPoint.x, firstPoint.y)
          ctx.closePath()
          ctx.stroke()
        }
      },
  
      paintBoundingBox(detectedCodes, ctx) {
        for (const detectedCode of detectedCodes) {
          const {
            boundingBox: { x, y, width, height }
          } = detectedCode
  
          ctx.lineWidth = 2
          ctx.strokeStyle = '#007bff'
          ctx.strokeRect(x, y, width, height)
        }
      },
  
      paintCenterText(detectedCodes, ctx) {
        for (const detectedCode of detectedCodes) {
          const { boundingBox, rawValue } = detectedCode
  
          const centerX = boundingBox.x + boundingBox.width / 2
          const centerY = boundingBox.y + boundingBox.height / 2
  
          const fontSize = Math.max(30, (50 * boundingBox.width) / ctx.canvas.width)
          logger.info(boundingBox.width, ctx.canvas.width)
  
          ctx.font = `bold ${fontSize}px sans-serif`
          ctx.textAlign = 'center'
  
          ctx.lineWidth = 3
          ctx.strokeStyle = '#35495e'
          const text = this.extractIdentifier(rawValue);
          logger.info(text);
          ctx.strokeText(text, centerX, centerY)
  
          ctx.fillStyle = '#5cb984'
          ctx.fillText(text, centerX, centerY)
        }
      },

      async extractIdentifier(rawValue) {
          const parts = rawValue.split('/');

          const identifier = parts[parts.length - 1];
          const foundThing = await this.thingsStore.fetchThingSimple(identifier);
          
          logger.info(foundThing);
            return foundThing.id;

          
        },
          
      logErrors: console.error
    }
  }
  </script>