import { IconPicture } from '@codexteam/icons';
import { capitalize } from 'lodash';
import logger from '../logger.mjs';

class ThingyUpload {
    constructor({ data, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly; // Storing the readonly state
        this.button = null;
        this.blockIndex = this.api.blocks.getCurrentBlockIndex() + 1;
        this.container = undefined;
        this.uuid = '';

        this.data = {
            url: data.url || '',
            type: data.type || '',
            subtype: data.subtype || '',
            filename: data.filename || '',
        };
    }

    static get isReadOnlySupported() {
        return true;
      }

    static get toolbox() {
        return {
            title: 'Images, Files, Videos',
            icon: IconPicture // Add your icon SVG here
        };
    }

    render() {
        this.container = document.createElement('div');

        if (this.readOnly) {
            // Render a non-interactive version of your plugin

            this._handleRenderingMedia();

            return this.container;
        }
        else {

            // Create the upload button to triggerd uploadcare
            this.button = document.createElement('button');
            this.button.type = 'button';
            this.button.addEventListener('click', this.openUploadcareDialog.bind(this));
            this.button.className = 'w-full border border-gray-300 py-2 px-4 hover:bg-gray-50 flex items-center justify-center space-x-2 inline-flex items-center gap-x-1.5 rounded-b-md bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50';
            
            if(!this.data.url)
            {
                this.button.innerHTML = `${IconPicture} ${this.api.i18n.t('Select an Image, Video or Attachment')}`;
            }
            else this.button.innerHTML = `${IconPicture} ${this.api.i18n.t('Replace ' + capitalize(this.data.type))}`;

            this._handleRenderingMedia();
            // Append elements to the container
            this.container.appendChild(this.button);

            return this.container;
        }
    }

    openUploadcareDialog() {
        const uploaderCtx = document.querySelector('.editorjsuploader')
        uploaderCtx.initFlow();

        this.handleUploadFinish = this.handleUploadFinish.bind(this);
        uploaderCtx.addEventListener('upload-finish', this.handleUploadFinish);

        this.handleCloudModification = this.handleCloudModification.bind(this);
        uploaderCtx.addEventListener('cloud-modification', this.handleCloudModification);
    }


    handleUploadFinish(e) {
        logger.info(e.detail[0].cdnUrl); // Handle the upload finish event
        logger.info('Upload finished for this instance.');

        logger.info(e);
        logger.info(e.detail[0]);
        logger.info(e.detail[0].originalFilename);


        const mime = e.detail[0].contentInfo.mime;
        const filename = e.detail[0].originalFilename;
        this.data.url = e.detail[0].cdnUrl;
        this.uuid = e.detail[0].uuid;
        this.data.filename = filename;

        if(mime.type=='video') this.data.type='video';
        else if(mime.type=='image') this.data.type='image';
        else this.data.type='document'

        this.data.subtype = mime.subtype;

        this._handleRenderingMedia();


        // Cleanup: Remove the event listener to prevent memory leaks or duplicate handling
        const uploaderCtx = document.querySelector('.editorjsuploader');
        if (uploaderCtx) {
            uploaderCtx.removeEventListener('upload-finish', this.handleUploadFinish);
        }
    }

    handleCloudModification(e) {
        logger.info('cloud mofidication');
        logger.info(e); // Handle the upload finish event

        this.data.url = "https://ucarecdn.com/" + this.uuid +'/' + e.detail;
        console.log(this.uuid);

        this._handleRenderingMedia();

        
        const uploaderCtx = document.querySelector('.editorjsuploader');
        if (uploaderCtx) {
            uploaderCtx.removeEventListener('cloud-modification', this.handleUploadFinish);
        }
    }



    _handleRenderingMedia() {
        if(this.data.type=='video') this._createVideo(this.data.url);
        if(this.data.type=='image') this._createImage(this.data.url);
        if(this.data.type=='document') this._createDocument(this.data.url);
    }

    _createImage(url) {
        const image = document.createElement('img');
        image.className = 'w-full object-cover mt-4';

        image.src = url;

        this.container.innerHTML = '';
        this.container.appendChild(image);
    }

    _createVideo(url) {
        const video = document.createElement('video');
        video.className = 'w-full object-cover mt-4';

        video.src = url;
        video.controls = true;

        this.container.innerHTML = '';
        this.container.appendChild(video);
    }

    

    _createDocument(url) {
        // Create a div to act as a wrapper for image and filename
        const wrapper = document.createElement('div');
        wrapper.className = 'flex items-center mt-2 mb-2'; // Use TailwindCSS classes for flexbox layout
    
        // Create an img element
        const image = document.createElement('img');
        image.className = 'inline-block w-10 mr-2'; // 'block' replaced with 'inline-block' and removed margins
    
        const extension = getFileExtension(this.data.filename).toLowerCase();

        const iconMap = {
            pdf: '/assets/icons/pdf_icon.png',
            xls: '/assets/icons/xls_icon.png',
            xlsx: '/assets/icons/xls_icon.png', 
            doc: '/assets/icons/doc_icon.png',
            docx: '/assets/icons/doc_icon.png', 
            txt: '/assets/icons/txt_icon.png',
        };
    
        image.src = iconMap[extension] || '/assets/icons/text_icon.png'; // Fallback icon
    
        // Create a span element for the filename
        const filename = document.createElement('span');
        filename.className = 'inline-block text-lg'; // Added inline-block class and text-lg for larger text
        filename.textContent = this.data.filename; // Assuming data.filename stores the filename
    
        // Create an anchor (a) element
        const anchor = document.createElement('a');
        anchor.href = url; // Set the href to the provided URL
        anchor.target = '_blank'; // Ensure it opens in a new window/tab
        anchor.rel = 'noopener noreferrer'; // Security measures for opening links in a new tab
        anchor.className = 'flex items-center no-underline'; // Apply flex layout to the anchor itself and remove underline
        anchor.appendChild(image); // Append the image to the anchor
        anchor.appendChild(filename); // Append the filename next to the image
    
        // Append the anchor to the wrapper
        wrapper.appendChild(anchor);
    
        // Clear the container and append the wrapper
        this.container.innerHTML = '';
        this.container.appendChild(wrapper);
    }
    
    
    
    
    

    save(blockContent) {
        logger.info(blockContent);
        return {
            url: this.data.url,
            type: this.data.type,
            filename: this.data.filename,
            subtype: this.data.subtype
            //     text: this.button.textContent
        };
    }
}

function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

export default ThingyUpload;
