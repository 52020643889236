<template>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
    <div class="mx-auto max-w-3xl">

      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Users</h1>
          <p class="mt-1 text-sm text-gray-500">A list of all the users in your account including their name, email and status.</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button type="button" @click="handleCreateInvite"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Invite users</button>
        </div>
      </div>

      <div v-if="inviteLink" class="mt-8">
        <div class="bg-gray-100 sm:rounded-lg">

          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg font-semibold text-gray-900">Invite Ready!</h3>
            <p class="mt-2 text-sm text-gray-800">Share this link to onboard more editors, they will have the same set of permissions as you so can edit, add and delete Things.</p>
            <div class="mt-6">
              <div @click="copyToClipboard" class="p-4 bg-white rounded-lg border border-gray-300 text-sm font-mono text-gray-800 break-words">
  {{ inviteLink }}
</div>              <button @click="copyToClipboard" type="button" class="mt-4 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <ClipboardDocumentIcon class="h-5 w-5 mr-2"></ClipboardDocumentIcon>
                Copy Link
              </button>
              </div>
          </div>

        </div>
      </div>

      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name, Email</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="person in people" :key="person.email">
                  
                  <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div class="flex items-center">
                      <div class="h-11 w-11 flex-shrink-0">
                        <img class="h-11 w-11 rounded-full" :src="person.imageUrl" alt="" />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">{{ person.name }}</div>
                        <div v-if="person.name!=person.email" class="mt-1 text-gray-500">{{ person.email }}</div>
                      </div>
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <span
                      class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Active</span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{{ person.role }}</td>
                  <td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <button v-if="person.id!=currentUser.id" @click="removePerson(person.id)" type="button" class="text-indigo-600 hover:text-indigo-900">Remove<span class="sr-only">, {{ person.name }}</span></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { onMounted, computed, ref } from 'vue';
import { usePeopleStore } from '@/stores/peopleStore';
import { useCustomerStore } from '@/stores/customerStore';
import { ClipboardDocumentIcon } from '@heroicons/vue/24/outline'

import { useDialogStore } from '@/stores/dialogStore';
import { auth0 } from '@/auth';

import logger from '@/utils/logger.mjs'



const customerStore = useCustomerStore();
const peopleStore = usePeopleStore();

const domainLimit = ref('');
const maxUses = ref(null);
const expirationDays = ref(null);
const inviteLink = ref('');
const dialogStore = useDialogStore();

const currentUser = computed(() => {
  if (auth0.user.value) { // Assuming auth0.user is a ref; adjust if it's not
    return {
      ...auth0.user.value, // Spread all existing user properties
      id: auth0.user.value.sub, // Add or overwrite the id property with the value of sub
    };
  }
  return null;
});


onMounted(async () => {
    await peopleStore.fetchPeople();
});


const handleCreateInvite = async () => {
  try {
    const inviteData = {
      domainLimit: domainLimit.value,
      maxUses: maxUses.value,
      expirationDays: expirationDays.value
    };
    const { publicId } = await customerStore.createInvite(inviteData); // Adjust according to your API response
    inviteLink.value = `${window.location.origin}/invite/${publicId}`;
  } catch (error) {
    // Handle errors
  }
};

const copyToClipboard = () => {
  if (inviteLink.value) {
    navigator.clipboard.writeText(inviteLink.value);
    // Optionally show a message indicating the link has been copied
  }
};

const removePerson = (personId) => {

  dialogStore.openDialog({
    title: 'Confirm Removal',
    message: 'Are you sure you want to remove this user?',
    onConfirm: async () => {
      logger.info('Action confirmed');
      peopleStore.removePersonById(personId);
    },
    onCancel: () => {
      logger.info('Action canceled');
      // Handle the cancel action
    },
  });
};

const people = computed(() => peopleStore.filteredPeople);
</script>
  