// customerStore.js

import { defineStore } from 'pinia';
import httpService from '@/api/httpService.js'; // Ensure you have an HTTP service set up for API calls

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    currentCustomer: null,
    isLoading: false,
    error: null,
    stripeSession: null,
    subscription: {
    }
  }),

  actions: {
    async getStripeSession() {
      const response = await httpService.get('/customer/stripeSession');
      this.stripeSession = response.data.stripeSession;
    },

    async getStripeBillingLink() {
      const response = await httpService.get('/customer/stripeBilling');
      return response.data.session.url;
    },
    
    async fetchCustomer() {
      try {
        this.isLoading = true;
        const response = await httpService.get(`/customer/`);
        this.currentCustomer = response.data;
        this.subscription = response.data.subscription;

      } catch (error) {
        console.error('Error fetching customer:', error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },

    async createCustomer(customerData) {
      try {
        this.isLoading = true;
        const response = await httpService.post('/customer', customerData);
        this.customers.push(response.data); // Optionally add the new customer to the local state
      } catch (error) {
        console.error('Error creating customer:', error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },

    async createInvite(inviteData) {
      try {
        this.isLoading = true;
        const response = await httpService.post('/customer/invite', inviteData);

        return response.data;
        // Handle the response as needed, e.g., update state, show success message, etc.
      } catch (error) {
        console.error('Error creating invite:', error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },

    async claimInvite(inviteId) {
      try {
        this.isLoading = true;
        await httpService.post(`/customer/invite/claim/${inviteId}`);
        // Handle the response, e.g., update state or show a success message
      } catch (error) {
        console.error('Error claiming invite:', error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },

  }
});
