import { createApp } from 'vue';

import App from '@/App.vue';
import router from '@/router/router';
import { auth0 } from '@/auth';
import { createPinia } from 'pinia';
import { initializeHttpClient } from '@/api/httpService';

import * as LR from "@uploadcare/blocks";
import '@/styles/output.css';
import 'mapbox-gl/dist/mapbox-gl.css';


LR.registerBlocks(LR);

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(auth0);

initializeHttpClient();

app.mount('#app');