// peopleStore.js
import { defineStore } from 'pinia';
import httpService from '@/api/httpService.js';

export const usePeopleStore = defineStore('people', {
  state: () => ({
    people: [],
    person: null,
    isPeopleLoading: false,
    isPersonLoading: false,
    searchQuery: '',
  }),

  getters: {
    filteredPeople: (state) => {
      // First, filter the people based on the search query
      let filtered = state.people.filter(person =>
        person.name.toLowerCase().includes(state.searchQuery.toLowerCase())
      );

      // Then, sort the filtered results
      return filtered.sort((a, b) => {
        let nameA = a.name.toLowerCase(); // Convert to upper case to ignore case during comparison
        let nameB = b.name.toLowerCase(); // Convert to upper case to ignore case during comparison

        // Split names into first and last names for comparison
        let [firstNameA, lastNameA = ""] = nameA.split(' ');
        let [firstNameB, lastNameB = ""] = nameB.split(' ');

        // Compare by first name first
        if (firstNameA < firstNameB) return -1;
        if (firstNameA > firstNameB) return 1;

        // If first names are the same, compare by last name
        if (lastNameA < lastNameB) return -1;
        if (lastNameA > lastNameB) return 1;

        return 0; // names must be completely equal
      });
    },
  },

  actions: {
    async fetchPeople() {
      try {
        this.isPeopleLoading = true;
        const response = await httpService.get('/people');
        this.people = response.data;
        this.isPeopleLoading = false;
      } catch (error) {
        console.error('Error fetching people:', error);
        this.isPeopleLoading = false;
      }
    },

    async fetchPersonById(personId) {

      if(!personId) return;

      // First, check if the person is already in the store
      const existingPerson = this.people.find(p => p.id === personId);

      if (existingPerson) {
        this.person = existingPerson;
        return this.person;
      }
  
      // If not in the store, fetch from the API
      try {
        this.isPersonLoading = true;
        let encodedUserId = encodeURIComponent(personId);

        const response = await httpService.get(`/people/${encodedUserId}`);
        const fetchedPerson = response.data;
  
        // Update the current person
        this.person = fetchedPerson;
  
        // Cache the fetched person in the people list
        this.people.push(fetchedPerson);
  
        this.isPersonLoading = false;
        return this.person;

      } catch (error) {
        console.error('Error fetching person:', error);
        this.isPersonLoading = false;
      }
    },

    selectPerson(selectedPerson) {
      this.fetchPersonById(selectedPerson.id);
    },

    clearPerson() {
      this.person = null;
    },

    setSearchQuery(query) {
      this.searchQuery = query;
    },

    resetSearchQuery() {
      this.searchQuery = '';
    },
    
    removePersonById(personId) {
      // Filter out the person with the given id
      this.people = this.people.filter(person => person.id !== personId);
  
      // If the currently selected person is the one being removed, clear the selection
      if (this.person && this.person.id === personId) {
        this.clearPerson();
      }
  
      // Future implementation: API call to delete the person from the backend
      // httpService.delete(`/people/${encodeURIComponent(personId)}`)
      //   .then(response => {
      //     // Handle response
      //   })
      //   .catch(error => {
      //     console.error('Error removing person:', error);
      //   });
    },
  }
});
