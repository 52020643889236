<template>
  <lr-file-uploader-regular
    id="editorjs-uploader"
    class="my-locale-override"
    css-src="https://cdn.jsdelivr.net/npm/@uploadcare/blocks@0.30.5/web/lr-file-uploader-regular.min.css"
    ctx-name="uploaderctx"
    />

  <lr-config 
    ctx-name="uploaderctx"
    pubkey="5f7de160ebd3ed758bb0" 
    maxLocalFileSizeBytes="100000000" 
    :imgOnly="props.imgOnly"
    multiple="false" 
    sourceList="local, camera, gdrive,dropbox, onedrive, box" 
    useCloudImageEditor="true" 
    :cropPreset="props.cropRatio"
    removeCopyright="true"
    accept=".jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff, .doc, .docx, .xls, .xlsx, .csv, .pdf, .mp4, .avi, .mov, .wmv"
    style="border:0; display:none;"
    />

  <lr-data-output 
    ctx-name="uploaderctx"
    use-input 
    use-event
  />

  <lr-upload-ctx-provider 
    id="editorjs-uploader"
    class="editorjsuploader"
    ref="uploaderProvider"
    ctx-name="uploaderctx"
    @done-flow="handleDoneFlow"
  />

</template>
  
<script setup>
import { ref, onMounted } from 'vue';
import logger from '@/utils/logger.mjs'

const uploaderProvider = ref(null);

const props = defineProps({
  field: String,
  cropRatio: {
    type: String,
    required: false
  },
  imgOnly: {
    type: Boolean,
    required: false,
    default: false
  }
});


const handleDoneFlow = (e) => {
  logger.info('editorjs update flow is done');
  logger.debug(e);

  if (uploaderProvider.value && uploaderProvider.value.uploadCollection) {
    uploaderProvider.value.uploadCollection.clearAll();
  }
}


onMounted(() => {
  const uploader = document.getElementById('editorjs-uploader');

// Check if the uploader is correctly selected and has shadowRoot
if (uploader && uploader.shadowRoot) {
  const style = document.createElement('style');
  style.textContent = `
    lr-simple-btn {
      display: none;
    }
  `;
  // Append the style only to the shadow DOM of this specific instance
  uploader.shadowRoot.appendChild(style);
  }});

</script>
  
<style>
.my-config {
  --darkmode: 0;
  --h-accent: 210;
  --s-accent: 100%;
  --l-accent: 50%;
}

.my-locale-override {
  --l10n-upload-files: 'Choose Stuff';
}

</style>
  