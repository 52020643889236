import { createRouter, createWebHistory } from 'vue-router';
import { auth0, authGuard } from "@/auth";
import { useCommonStore } from '@/stores/commonStore';

import ThingsView from '@/views/ThingsView';
import ScannerView from '@/views/ScannerView'
import PublicThingView from '@/views/PublicThingView'
import LoginCallbackView from '@/views/LoginCallbackView';
import TestingView from '@/views/TestingView';
import LogoutView from '@/views/LogoutView';
import PeopleView from '@/views/PeopleView';
import NewThingView from '@/views/NewThingView';



// https://developer.auth0.com/resources/guides/spa/vue/basic-authentication

const routes = [
  {
    name: 'loading',
    path: '/loading',
    component: LoginCallbackView,
    meta: {
      headless: true,
    },
    beforeEnter: authGuard,
  },
  {
    name: 'auth-callback',
    path: '/auth-callback',
    component: LoginCallbackView,
    meta: {
      headless: true,
    },
    beforeEnter: authGuard,
  },
  {
    name: 'logout',
    path: '/logout',
    component: LogoutView,
    meta: {
      headless: true,
      public:true
    }
  },
  {
    name: 'testing',
    path: '/testing',
    component: TestingView,
    meta: {
      public:true
    }
  },
  {
    name: 'things-public',
    path: '/pt/:thingId',
    component: PublicThingView,
    props: true,
    meta: {
      headless: true,
      readonly: true,     
      public:true
    }
  },
  {
    name: 'things',
    path: '/things',
    component: ThingsView,
    beforeEnter: authGuard,
    meta: {
      headless: false,
    }
  },

  {
    name: 'create-thing',
    path: '/create-thing',
    component: NewThingView,
    beforeEnter: authGuard,
    scrollBehavior() {
      return { top: 0 };
    },
  },

  {
    name: 'subscribe',
    path: '/subscribe',
    component:() => import('@/views/SubscribeView.vue'),
    beforeEnter: authGuard,
    scrollBehavior() {
      return { top: 0 };
    },
  },

  {
    name: 'people',
    path: '/people',
    component: PeopleView,
    beforeEnter: authGuard,
    scrollBehavior() {
      return { top: 0 };
    },
  },
  {
    name: 'root',
    path: '/',
    component: ThingsView,
    beforeEnter: authGuard,
  },
  {
    name: 'scanner',
    path: '/scaner',
    component: ScannerView,
    beforeEnter: authGuard,
  },
  {
    name: 'things-details',
    path: '/things/:thingId',
    component: ThingsView,
    props: true,
    beforeEnter: authGuard,
  },
  {
    name: 'things-table',
    path: '/things/table',
    component: () => import('@/views/ThingsTableView'),
    props: true,
    beforeEnter: authGuard,
  },
  {
    name: 'error500',
    path: '/x500',
    component: () => import('@/views/Error500View'),
    meta: {
      headless: true
    }
  },

  {
    name: 'error400',
    path: '/x400',
    component: () => import('@/views/Error400View'),
    meta: {
      headless: true
    }
  },

  {
    name: 'signup',
    path: '/signup',
    component: () => import('@/views/SignupView.vue'),
    meta: {
      headless: true,
      public:true
      },
    beforeEnter: authGuard,
  },
  {
    name: 'billing',
    path: '/billing',
    component: () => import('@/views/BillingView.vue'),
    meta: {
      headless: true,
      },
    beforeEnter: authGuard,
  },
  {
    name: 'invite',
    path: '/invite/:inviteId',
    component: () => import('@/views/InviteView.vue'),
    meta: {
      headless:true,
      public:true
      }
  }
];

const router = createRouter({
  history: createWebHistory(),
  //history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const commonStore = useCommonStore();

  commonStore.setHeadless(!!to.meta.headless);
  commonStore.setReadOnly(!!to.meta.readonly);

  next();
});

router.beforeEach(async (to, from, next) => {
  const { customerId, sub } = auth0?.user?.value || {};
  
  if (to.name === 'signup' || to.name === 'invite' || to.name === 'loading') {
    next(); // This should allow users to go to invite without customerId
  } else if (!customerId && sub) {
    next({ name: 'signup' }); // Redirect to signup if customerId is missing but sub exists
  } else {
    next(); // Proceed to the intended route
  }
});




export default router;
