import { defineStore } from 'pinia';
import httpService from '@/api/httpService.js';

export const useTemplatesStore = defineStore('templates', {
  state: () => ({
    templates: [],
    template: null,
  }),
  
  actions: {
    async fetchTemplates() {
      try {
        const response = await httpService.get('/templates');
        this.templates = response.data;
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchTemplateById(templateId) {
      try {
        const response = await httpService.get(`/templates/${templateId}`);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  }
});
