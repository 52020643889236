<template>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-12">
    <div class="mx-auto max-w-3xl">

      <h2 class="text-base font-semibold leading-6 text-gray-900">Start Your Thing</h2>
    <p class="mt-1 text-sm text-gray-500">Choose a template for your Thing, or start from scratch and create something new.</p>
    <ul role="list" class="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
      <li v-for="(item, itemIdx) in items" :key="itemIdx" class="flow-root">
        <div class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
          <div :class="[item.background, 'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg']">
            <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <div>
            <h3 class="text-sm font-medium text-gray-900">
              <a href="#" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                <span>{{ item.title }}</span>
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </h3>
            <p class="mt-1 text-sm text-gray-500">{{ item.description }}</p>
          </div>
        </div>
      </li>
    </ul>
    <div class="mt-4 flex">
      <a href="#" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">
        Or start with a blank Thing
        <span aria-hidden="true"> &rarr;</span>
      </a>
    </div>
  </div>
  </div>
</template>




<script setup>

import {
  PrinterIcon,
  ShieldCheckIcon,
  TruckIcon,
  AcademicCapIcon,
  HomeIcon,
  TrophyIcon,
  BuildingOfficeIcon,
  CogIcon,

  // MapIcon,
  // CubeTransparentIcon,
  // TicketIcon,
  // ShoppingBagIcon,
  // BookOpenIcon,
  // BuildingLibraryIcon,
  // DocumentIcon,
} from '@heroicons/vue/24/outline'

const items = [
   
    {
        title: "Office Equipment",
        description: "Quick access to guides and manuals for office devices, warranty info, and service requests.",
        icon: PrinterIcon,
        background: "bg-blue-500"
    },
    {
        title: "Machinery",
        description: "Information and guides for industrial and agricultural machinery.",
        icon: CogIcon,
        background: "bg-pink-500" // Assuming gray as a suitable color for machinery
    },
    {
        title: "Safety Equipment",
        description: "Usage instructions and safety compliance for gear like helmets, harnesses, or fire extinguishers.",
        icon: ShieldCheckIcon,
        background: "bg-yellow-500"
    },
    {
        title: "Cars",
        description: "Car details and virtual experiences in showrooms, maintenance videos, and owner manuals.",
        icon: TruckIcon,
        background: "bg-red-500"
    },
    {
        title: "Museum Exhibits",
        description: "Artifacts descriptions, historical context, artist biographies, and interactive tours.",
        icon: AcademicCapIcon,
        background: "bg-green-500"
    },
    // {
    //     title: "Information Boards",
    //     description: "Maps, local wildlife, historical facts in parks or tourist areas, event schedules at conventions.",
    //     icon: MapIcon,
    //     background: "bg-orange-500"
    // },
    // {
    //     title: "Product Packaging",
    //     description: "Nutritional info, ingredient stories, or recipes on food packaging. Assembly instructions for goods.",
    //     icon: CubeTransparentIcon,
    //     background: "bg-purple-500"
    // },
    {
        title: "Real Estate",
        description: "Virtual property tours, neighborhood info, agent contacts, and viewing schedules.",
        icon: HomeIcon,
        background: "bg-teal-500"
    },
    // {
    //     title: "Event Posters",
    //     description: "Event details, ticket links, performance previews, venue directions, and nearby accommodations.",
    //     icon: TicketIcon,
    //     background: "bg-pink-500"
    // },
    {
        title: "Fitness Equipment",
        description: "Instructional videos and workout routines for gym equipment.",
        icon: TrophyIcon,
        background: "bg-indigo-500"
    },
    // {
    //     title: "Fashion Retail",
    //     description: "Design stories, sustainable practices, styling tips, care instructions, and online ordering options.",
    //     icon: ShoppingBagIcon,
    //     background: "bg-violet-500"
    // },
    // {
    //     title: "Books",
    //     description: "Author interviews, behind-the-scenes content, or related educational material. Interactive reader engagement.",
    //     icon: BookOpenIcon,
    //     background: "bg-green-500"
    // },
    // {
    //     title: "Educational Institutions",
    //     description: "Virtual campus tours, department information, event calendars, and supplemental educational resources.",
    //     icon: BuildingLibraryIcon,
    //     background: "bg-lime-500"
    // },
    {
        title: "Hospitality Industry",
        description: "Access to hotel services, local guides, restaurant recommendations, and transportation options.",
        icon: BuildingOfficeIcon,
        background: "bg-rose-500"
    },
    // {
    //     title: "Nature Trails and Parks",
    //     description: "Guides to local flora and fauna, trail maps, conservation info, ecosystem education.",
    //     icon: MapIcon,
    //     background: "bg-cyan-500"
    // },
    // {
    //     title: "Gardening and Horticulture",
    //     description: "Plant care instructions, gardening tips, landscaping ideas, and species info.",
    //     icon: DocumentIcon,
    //     background: "bg-amber-500"
    // }
]

</script>