<template>
    <div v-if="!thingsStore.isThingsLoading">
      <div v-if="thingsStore.things.length > 0">

      <!-- List of things -->
      <div :class="calculateThingyListClasses()">
        <ThingyList v-if="!commonStore.headless" @selectedThingId="handleSelectThingId"></ThingyList>
        <!-- <button @click="toggleExpand" class="toggle-button">'>'</button> -->
      </div>

      <!-- Description of things -->
      <div :class="calculateThingyDescriptionClasses()">
        <ThingyDetails @close="handleClose"></ThingyDetails>
      </div>
      </div>
      <div v-else>
        <NoThingsComponent></NoThingsComponent>>
      </div>
    </div>
</template>


<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useThingsStore } from '@/stores/thingsStore';
import { useCommonStore } from '@/stores/commonStore'; // Adjust the import path as needed
import ThingyDetails from '@/components/ThingyDetails.vue';
import ThingyList from '@/components/ThingyList.vue';
import NoThingsComponent from '@/components/NoThingsComponent.vue';
import isMobile from '@/utils/isMobile';


const router = useRouter();
const route = useRoute();
const thingsStore = useThingsStore();
const isExpanded = ref(false); // Tracks if the list is expanded
const commonStore = useCommonStore();

function handleClose() {
  thingsStore.clearThing();
}

onMounted(async () => {
  await thingsStore.fetchThings();
  const thingId = route.params.thingId || thingsStore.filteredThings[0]?.id;
  if (thingId) {
    await thingsStore.fetchThingById(thingId);
    handleSelectThingId(thingId);
  }
});

watch(() => route.params.thingId, async (newThingId) => {
  
  if (newThingId) {
    thingsStore.fetchThingById(newThingId);
  } else {
    handleSelectThingId(thingsStore.filteredThings[0]?.id);

    //thingsStore.clearThing();
  }
});


const handleSelectThingId = async (selectedThingId) => {
  router.push({ name: 'things-details', params: { thingId: selectedThingId } });
};

function calculateThingyListClasses() {
  let classes = "fixed bottom-0 w-full sm:left-20 top-16 sm:w-80 overflow-y-auto border-r border-gray-200 xl:block";
  if (thingsStore.thing && isMobile()) {
    classes += ' hidden';
  }
  if (isExpanded.value) {
    classes = 'fixed inset-0 sm:left-20 top-16 overflow-y-auto'; // Adjust this class for full width
  }
  return classes;
}

function calculateThingyDescriptionClasses() {
  let classes = '';
  if (thingsStore.thing && !isMobile()) {
    classes = 'hidden sm:block pl-80 overflow-y-auto';
  }
  if (isExpanded.value) {
    classes = 'hidden';
  }
  return classes;
}
</script>

<style>
.bottom-cover {
  object-fit: cover;
  object-position: bottom;
}

.toggle-button {
  @apply border-gray-400 border text-gray-500 p-2 rounded-full;
  /* Basic styling */
  position: absolute;
  top: 15px;
  /* Center vertically */
  width: 15px;
  height: 15px;
  font-size: 12px;
  right: 1rem;
  /* Position at the right edge */
  transform: translate(50%, -50%);
  /* Adjust to place half outside */
  z-index: 5000;
  /* Ensure it's above other content */
}</style>

