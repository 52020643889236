import { defineStore } from 'pinia';
import httpService from '@/api/httpService.js';
import logger from '@/utils/logger.mjs';

export const useThingsStore = defineStore('things', {
  state: () => ({
    things: [],
    thing: {},
    isThingsLoading: false,
    isThingLoading: false,
    searchQuery: '', // Add a searchQuery state

  }),

  getters: {
    // Getter for filtering things based on the search query
    filteredThings: (state) => {
      const searchLower = state.searchQuery.toLowerCase();
      const filtered = state.things.filter(thing =>
        ['name', 'location', 'id'] // Assuming 'location' was mistakenly included twice in your original code
        .some(field => thing[field] && thing[field].toLowerCase().includes(searchLower))
      );
      // Then, sort the filtered things by name
      return filtered.sort((a, b) => a.name.localeCompare(b.name));
    },
    // Getter that applies both filtering and grouping to the things
    filteredAndGroupedThings: (state, getters) => {
      return groupThings(getters.filteredThings);
    },
  },

  
  
  actions: {

    async fetchAllThings() {
      try {
        this.isThingsLoading = true;
        const response = await httpService.get(`/things?showAll=true`);
        this.things = response.data;
        
        this.isThingsLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchThings(fields = []) {
      try {
        this.isThingsLoading = true;
        const fieldsQuery = fields.join(',');
        const response = await httpService.get(`/things?fields=${fieldsQuery}`);
        this.things = response.data;
        
        this.isThingsLoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchThingById(thingId) {
      try {
        this.isThingLoading = true;
        const response = await httpService.get(`/things/${thingId}`);
        this.thing = response.data;
        //await this.fetchThingyLabel();
        this.isThingLoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async selectThing(selectedThing) {
      if (selectedThing && selectedThing.id) {
        try {
          await this.fetchThingById(selectedThing.id);
        } catch (error) {
          console.error('Error selecting thing:', error);
        }
      } else {
        console.error('Invalid thing selected');
      }
    },

    setSearchQuery(query) {
      this.searchQuery = query;
    },

    resetSearchQuery() {
      this.searchQuery = '';
    },

    getSearchQuery() {
      return this.searchQuery;
    },

    async fetchThingSimple(thingId) {
      // Reset current thing
      this.thing = null;

      // Find the thing by ID in the things array
      const foundThing = this.things.find(thing => thing.id === thingId);
      // If found, set it as the current thing
      if (foundThing) {
        this.thing = foundThing;
      } else {
        console.error('Thing not found with ID:', thingId);
      }
    },

    

    async createThing(templateId, includeSampleData = true) {
      try {
        // Make a POST request to the /things endpoint with the templateId
        const response = await httpService.post('/things', 
        { 
          templateId: templateId,
          includeSampleData: includeSampleData
        }
        );
        const newThing = response.data;
        
        await this.fetchThings();

        return newThing.publicId;
      } catch (error) {
        console.error('Error creating new thing:', error);
        throw error; 
      }
    },


    async cloneThing(sourcePublicId) {
      try {
        // Ensure there's a sourcePublicId to work with
        if (!sourcePublicId) {
          console.error('No sourcePublicId provided for cloning');
          return;
        }
        // Call the API endpoint to clone the thing
        const response = await httpService.post('/things/clone', {
          sourcePublicId: sourcePublicId
        });
    
        if (response && response.data) {
          const clonedThingPublicId = response.data.publicId;
          await this.fetchThings(); 
          return clonedThingPublicId;
        }
      } catch (error) {
        console.error('Error cloning thing:', error);
        throw error;
      }
    },
    


    clearThing() {
      this.thing = null;
    },

    async archive() {
      if (!this.thing || !this.thing.id) {
        console.error('No thing selected to archive');
        return;
      }
      // Call updateField with specific values for archiving
      await this.updateField({ path: "isVisible", value: false });

      // Remove the thing from the things array
      this.things = this.things.filter(t => t.id !== this.thing.id);
      this.clearThing();
      await this.fetchThings(); 

    },

    async fetchThingyLabels() {
      try {
        // Adjust the URL/path as necessary. This example assumes you pass the thing ID as a query parameter.
        const response = await httpService.get(`/things/${this.thing.id}/qrlabel`);
        this.thing.qrLabels = response.data;
        
      } catch (error) {
        console.error('Error fetching thingy label:', error);
        this.isThingLoading = false;
      }
    },


    async updateField(field, thingId) {
      if (!thingId) {
        thingId = this.thing.id; // Fallback to a default thing ID if none is provided
        this.updateThingAtPath(this.thing, field.path, field.value);
      }
    
      try {
        const response = await httpService.patch(`/things/${thingId}`, {
          path: field.path,
          value: field.value
        });
    
        this.updateThingAtPath(this.thing, field.path, field.value);
    
        logger.info('Field updated successfully', response);
        logger.info(this.thing[field.path]); // Note: Might not reflect deep nested updates correctly due to direct path access
    
        const index = this.things.findIndex(t => t.id === thingId);
        if (index !== -1) {
          this.updateThingAtPath(this.things[index], field.path, field.value);
        } else {
          logger.info('Thing not found in things array');
        }
      } catch (error) {
        console.error('Failed to update field', error);
      }
    },

    async updateGPSLocation(data) {
      try {

        const gpsData = `${data.latitude},${data.longitude}`;
        await httpService.patch(`/things/${this.thing.id}/gps`, {
          value: gpsData,
          lat: data.latitude,
          lng: data.longitude
        });
    
        console.log(this.thing.fields)
        this.thing.fields.map.value = gpsData;
        console.log(this.thing.fields)
       
      } catch (error) {
        console.error('Failed to update field', error);
      }
    },
    
    // Helper method to update a field value by path
    updateThingAtPath(thing, path, value) {
      const keys = path.split('.');
      let current = thing;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
          // Optionally create the object if it doesn't exist
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }
      // Vue 3 should pick up this change if `thing` is reactive
      current[keys[keys.length - 1]] = value;
    }
  }
});

function groupThings(things) {
  return things.sort((a, b) => {
    const isALocationEmpty = !a.location || a.location.trim() === '';
    const isBLocationEmpty = !b.location || b.location.trim() === '';

    if (isALocationEmpty && !isBLocationEmpty) {
      return -1;
    }
    if (!isALocationEmpty && isBLocationEmpty) {
      return 1;
    }

    // Now that both have locations or are both empty, sort alphabetically by 'name'
    const nameA = a.name || '';
    const nameB = b.name || '';
    return nameA.localeCompare(nameB);
  });
}
