<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-50 lg:hidden" @close="closeSidebar">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
        enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0" leave-to="-translate-x-full">
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
              leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" class="-m-2.5 p-2.5" @click="closeSidebar">
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>

            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
              <div class="flex h-16 shrink-0 items-center">
                <img class="h-8 w-auto" src="/assets/thingy3.png" alt="Thingy Logo" />
              </div>
              <nav class="flex flex-1 flex-col">
                <ul role="list" class="-mx-2 flex-1 space-y-1">
                  <li v-for="item in navigation" :key="item.name">
                    <button @click="navigateAndCloseSidebar(item.routeName)"
      class="group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
      :class="{ 'text-gray-400 hover:text-white hover:bg-gray-800': $route.path !== item.href }"
    >
      <component :is="item.icon" :class="['h-6 w-6 shrink-0', item.customClass]" aria-hidden="true" />
      <span>{{ item.name }}</span>
      </button>

                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div
    class="hidden lg:fixed lg:inset-y-0 max-w-20 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-gray-900 lg:pb-4">
    <div class="flex h-16 shrink-0 items-center justify-center">
      <img class="mt-6 h-11 w-auto" src="/assets/thingy3.png" alt="Thingy Logo" />
    </div>
    <nav class="mt-8">
      <ul role="list" class="flex flex-col items-center space-y-1">
        <li v-for="item in navigation" :key="item.name">
          <router-link :to="{ name: item.routeName }"
            class="group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
            active-class="bg-gray-800 text-white" exact-active-class="bg-gray-800 text-white">
            <component :is="item.icon" :class="['h-6 w-6 shrink-0', item.customClass]" aria-hidden="true" />
            <span class="md:hidden">{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
  
<script setup>


import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  XMarkIcon,
  // QrCodeIcon,
  // CodeBracketIcon,
  UserGroupIcon,
  HomeIcon,
QueueListIcon
} from '@heroicons/vue/24/outline'
import { PlusCircleIcon } from '@heroicons/vue/24/solid'

import { useRouter } from 'vue-router';
const router = useRouter();

const navigation = [
  { name: 'Add Thing', routeName: 'create-thing', icon: PlusCircleIcon, customClass: 'text-indigo-500 fill-current' },
  { name: 'My Things', routeName: 'root', icon: HomeIcon },
  { name: 'Things', routeName: 'things-table', icon: QueueListIcon },

  { name: 'My Users', routeName: 'people', icon: UserGroupIcon },
  // { name: 'Scanner', routeName: 'scanner', icon: QrCodeIcon },
  // { name: 'PrintTest', routeName: 'print', icon: CodeBracketIcon },

];

defineProps({
  sidebarOpen: Boolean
});

const emit = defineEmits(['update:sidebarOpen']);

const closeSidebar = () => {
  emit('update:sidebarOpen', false);
};


const navigateAndCloseSidebar = (routeName) => {
  router.push({ name: routeName });
  closeSidebar();
};


</script>

<style>
/* Component-specific styles */
</style>