<template>
  <div>

    <div ref="editor" class="rounded">
    </div>

    <EditorjsUploadHelper></EditorjsUploadHelper>
    <EditorJsButtonHelper></EditorJsButtonHelper>
  </div>
</template>

<script setup>
import { getToken } from '@/auth';

import { ref, watch, onUnmounted, onMounted, nextTick } from 'vue';
import EditorJS from '@editorjs/editorjs';
import EditorjsUploadHelper from '@/components/EditorjsUploadHelper.vue';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import AlertTool from 'editorjs-alert';

import DragDrop from 'editorjs-drag-drop';

import logger from '@/utils/logger.mjs'
import thingUpload from '@/utils/editorPlugins/thingyUpload'
import EditorJsButtonHelper from './EditorJsButtonHelper.vue';
import CustomHtmlEmbed from '@/utils/editorPlugins/simpleEmbed.js'

/*
import FormHeader from '@/utils/editorPlugins/thingyToolboxHeader.js'
import FormFieldTextarea from '@/utils/editorPlugins/FormFieldTextarea.js'
import FormFieldInput from '@/utils/editorPlugins/FormFieldInput.js'
import MyBlockTune from '@/utils/editorPlugins/thingyDuplicateBlock.js'
*/

// import FormFieldChecklist from '@/utils/editorPlugins/FormFieldChecklist.js'



const emit = defineEmits(['update-content', 'update-field'])

const props = defineProps({
  data: Object,
  placeholder: String,
  inputClass: String,
  isReadOnly: Boolean,
  id: String,
});

const editor = ref(null);
const editorInstance = ref(null);
const isSaveRequired = ref(false);
const isInitializing = ref(true);
const currentThingId = ref(props.id);
const actionsObserver = ref(null);

watch(() => props.id, async (newId, oldId) => {
  if (newId !== oldId && oldId) {
    // Use oldId for saving data since newId is the updated prop value
    await reinitializeEditor(oldId);
    currentThingId.value = newId; // Update currentThingId after operations complete
  }
}, { immediate: true });

async function reinitializeEditor(oldThingId) {
  await saveData(oldThingId); // Pass oldThingId to saveData
  await destroyEditor();
  await initializeEditor();
}

const initializeEditor = async () => {
  logger.info('initializing editor');
  const token = await getToken();

  if (editor.value) {
    editorInstance.value = new EditorJS({
      holder: editor.value,
      readOnly: props.isReadOnly,
      onReady: () => {
        new DragDrop(editorInstance.value);
      },
      onChange: () => {
        if (!isInitializing.value) {
          logger.info('content changed');
          isSaveRequired.value = true;
          saveData();
        }
      },
    
    
      tools: {
        header: Header,
        
        list: List,
        alert: {
          class: AlertTool,
          /* to display inline tools, enable `inlineToolbar` option */
          inlineToolbar: true,
        },
        thingUpload: thingUpload,
        CustomHtmlEmbed: {

          class: CustomHtmlEmbed,
          config: {
            token: token, // Pass the token here
          }
        },
        // FormHeader:FormHeader,
        // FormFieldInput: FormFieldInput,
        // FormFieldTextarea : FormFieldTextarea,
        // FormFieldChecklist:FormFieldChecklist
  //        MyBlockTune:MyBlockTune,

      },
    //  tunes: ['MyBlockTune'],

    });

    try {


      await editorInstance.value.isReady;
      await nextTick();
      logger.info('Editor.js is ready!');
      isInitializing.value = true;


      if (props.data && props.data.blocks && props.data.blocks.length > 0) {
        await editorInstance.value.render(props.data);
        logger.info('loaded new props data');
      } else {
        logger.info('no props data to load');
      }

      await nextTick();

      setTimeout(() => {

        const toolbarActions = document.querySelector('.ce-toolbar');
        if (toolbarActions) {
          toolbarActions.classList.add('ce-toolbar--opened');
          console.log('updated class');
        }

      }, 100);


      setTimeout(() => {

        isInitializing.value = false;
        isSaveRequired.value = false;

      }, 1000);


    } catch (reason) {
      console.error(`Editor.js initialization failed because of ${reason}`);
    }
  }
};


const saveData = async (thingIdToSave) => {
  if (isInitializing.value === false && isSaveRequired.value === true) {
    logger.info('saving data');
    const savedData = await editorInstance.value.save();
    emit('update-content', { content: savedData, thingId: thingIdToSave });
    isSaveRequired.value = false;
  }
  else return;
};

const destroyEditor = async () => {
  logger.info('Destroying Editor');
  if (editorInstance.value && typeof editorInstance.value.destroy === 'function') {
    await editorInstance.value.destroy();
    logger.info('Editor destroyed');
    editorInstance.value = null;
  }
};

onMounted(async () => {
  await initializeEditor();
  // Use watchEffect to observe changes and adjust the toolbar's top style
  nextTick(() => {
    document.addEventListener('update-field', handleCustomEvent);

  });
});


function handleCustomEvent(event) {
  // Log the received event (optional)
  console.log('Received update-field event:', event.detail);

  // Re-emit the event in Vue
  emit('update-field', event.detail);
}

onUnmounted(async () => {
  await saveData();
  if (actionsObserver.value) {
    actionsObserver.value.disconnect();
    actionsObserver.value = null; // Cleanup
  }
  document.removeEventListener('update-field', handleCustomEvent);
});

</script>


<style>
/* .ce-toolbar {
  display:block !important;
}
*/
.ce-toolbar__actions {
  opacity: 1 !important;
}

.ce-header {
  padding: 0px !important
}

.ce-block a.no-underline {
  -webkit-text-decoration: none;
  text-decoration: none;
}


@media (min-width: 640px) {

  .codex-editor--narrow .ce-toolbar__actions {
    position: absolute;
    right: 100% !important;
    padding-right: 5px;
  }

  .codex-editor--narrow .ce-toolbox .ce-popover {
    position: absolute;
    left: 0;
    right: auto;
    padding-right: 5px;
  }
}

@media (max-width: 650px) {
  .ce-popover {
    position: relative !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 10px !important;
    top: 10px !important;
    border-radius: 10px !important;
  }
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}
</style>