<template>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-12">
    <div class="mx-auto max-w-3xl">
      <h2 class="text-base font-semibold leading-6 text-gray-900">Start Your Thing</h2>
      <p class="mt-1 text-sm text-gray-500">Choose a template or start from blank Thing to create something new.</p>

      <ul role="list" class="grid grid-cols-1 gap-6 border-gray-200 py-6 sm:grid-cols-2">
        <li v-for="(item, itemIdx) in templatesStore.templates" :key="itemIdx" class="flow-root">
          <div @click="openPreview(item.publicId)"
            class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
            <div :class="[item.background, 'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg']">
              <component :is="iconMapper[item.icon]" class="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <a href="#" class="focus:outline-none">
                  <span class="absolute inset-0" aria-hidden="true" />
                  <span>{{ item.name }}</span>
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </h3>
              <p class="mt-1 text-sm text-gray-500">{{ item.description }}</p>
            </div>
          </div>
        </li>
      </ul>
      <div class="mt-4 flex">
        
      </div>
    </div>
  </div>

  <ThingPreviewComponent :templateId="selectedTemplateId" :open="previewOpen" @update:open="previewOpen = $event" @createThing="eventData => handleCreateThing(selectedTemplateId, eventData)" />
</template>

  
<script setup>

import {
  PrinterIcon,
  ShieldCheckIcon,
  TruckIcon,
  AcademicCapIcon,
  HomeIcon,
  TrophyIcon,
  BuildingOfficeIcon,
  CogIcon,

  MapIcon,
  CubeTransparentIcon,
  TicketIcon,
  ShoppingBagIcon,
  BookOpenIcon,
  BuildingLibraryIcon,
  DocumentIcon,
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  MapPinIcon,
  CircleStackIcon,
} from '@heroicons/vue/24/outline'

import { ref, onMounted } from 'vue'
import { useTemplatesStore } from '@/stores/templatesStore';
import { useThingsStore } from '@/stores/thingsStore';
import { useRouter } from 'vue-router';
import  ThingPreviewComponent from '@/components/ThingPreviewComponent.vue'
import logger from '@/utils/logger.mjs';

const templatesStore = useTemplatesStore();
const thingsStore = useThingsStore();
const selectedTemplateId = ref(null);
const previewOpen = ref(false);
const router = useRouter();


const openPreview = (templateId) => {
  selectedTemplateId.value = templateId;
  previewOpen.value = true;
};

onMounted(async () => {
  templatesStore.fetchTemplates();
});

const iconMapper = {
  'PrinterIcon': PrinterIcon,
  'ShieldCheckIcon': ShieldCheckIcon,
  'TruckIcon': TruckIcon,
  'AcademicCapIcon': AcademicCapIcon,
  'HomeIcon': HomeIcon,
  'TrophyIcon': TrophyIcon,
  'BuildingOfficeIcon': BuildingOfficeIcon,
  'CogIcon': CogIcon,
  'MapIcon': MapIcon,
  'CubeTransparentIcon': CubeTransparentIcon,
  'TicketIcon': TicketIcon,
  'ShoppingBagIcon': ShoppingBagIcon,
  'BookOpenIcon': BookOpenIcon,
  'BuildingLibraryIcon': BuildingLibraryIcon,
  'BuildingStorefrontIcon': BuildingStorefrontIcon,
  'CalendarDaysIcon': CalendarDaysIcon,
  'MapPinIcon': MapPinIcon,
  'CircleStackIcon' : CircleStackIcon,
  'DocumentIcon':DocumentIcon
};

async function navigateToThingDetails(newThingId) {

  await thingsStore.fetchThingById(newThingId);
  router.push({
    name: 'things-details',
    params: { thingId:newThingId }
  });
}

const handleCreateThing = async (templateId, eventData) => {
  try {
    logger.info('Selected Template ID:', selectedTemplateId);
    logger.info('Include Sample Data:', eventData.includeSampleData);
    const newThingId = await thingsStore.createThing(templateId, eventData.includeSampleData); 
    logger.info('Created new thing with ID:', newThingId);
    navigateToThingDetails(newThingId);
  } catch (error) {
    console.error('Error creating new thing:', error);
  }
};

</script>