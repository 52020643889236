<template>
  <div v-if="templateDetails">
    <TransitionRoot as="template" :show="props.open">
      <Dialog as="div" class="relative z-10" @close="closeDialog">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 lg:w-1/2 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900"> {{ templateDetails.name
                    }}</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        {{ templateDetails.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button type="button"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    @click="createThing">Create Thing</button>
                  <button type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    @click="closeDialog" ref="cancelButtonRef">Cancel</button>
                </div>
                <div class="mt-2">
                  <div class="flex justify-end">
                    <div v-if="templateId!='blank'" class="relative flex items-start">
                      <div class="flex h-6 items-center">
                        <input id="sample-data" aria-describedby="sample-data" name="sample-data" type="checkbox" v-model="includeSampleData"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      </div>
                      <div class="ml-3 text-sm leading-6">
                        <label for="sample-data" class="font-light text-gray-900">Include sample data</label>
                      </div>
                    </div>
                  </div>
                </div>

              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import { useTemplatesStore } from '@/stores/templatesStore';
import logger from '@/utils/logger.mjs';

const emit = defineEmits(['update:open']);
const includeSampleData = ref(true); // State to track the checkbox


const props = defineProps({
  templateId: String,
  open: Boolean
});

const templateDetails = ref(null);
const templatesStore = useTemplatesStore();

// Watch for changes in templateId and fetch details accordingly
watch(() => props.templateId, async (newTemplateId) => {
  if (newTemplateId) {
    // Fetch template details based on templateId
    // Assuming templatesStore has a method to fetch a single template's details
    templateDetails.value = await templatesStore.fetchTemplateById(newTemplateId);
  }
}, { immediate: true });

const closeDialog = () => {
  logger.info('close dialog');
  emit('update:open', false);
};

const createThing = () => {
  emit('createThing', { includeSampleData: includeSampleData.value });
  emit('update:open', false);
}

</script>