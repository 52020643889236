<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center mt-20 sm:mt-0 sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">


                  <div v-if="thingsStore.thing.qrLabels"
                    class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                    
                    
                    <div 
  v-for="(action, actionIdx) in actions" 
  :key="action.title"
  :class="[
    actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none sm:rounded-bl-lg' : '', 
    actionIdx === 1 ? 'sm:rounded-tr-none' : '', 
    actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', 
    actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 
    'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500', 
    selectedLabel.value === action ? 'ring-2 ring-indigo-500' : ''
  ]"
  tabindex="0"
  @click="selectLabel(action)"
  @focus="selectLabel(action)"
  @keydown.space.prevent="selectLabel(action)"
  @keydown.enter.prevent="selectLabel(action)"
>

                      <div>
                        <span
      :class="[action.iconBackground, action.iconForeground, 'inline-flex items-center rounded-lg p-6 ring-4 ring-white']"
      style="min-height:260px"
    >
                          <img :src="action.qrCode" :style="{ 'max-height': action.qrCodeHeight + 'px' }">
                        </span>
                      </div>

                      <div class="mt-8">
                        <h3 class="text-base font-semibold leading-6 text-gray-900">
                          <button class="focus:outline-none" @click.prevent="selectLabel(action)">
        {{ action.title }}
      </button>
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">{{ action.description }}</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">


                <div class="inline-flex rounded-md shadow-sm">
                  <Menu as="div" class="relative inline-block text-left">
                    <MenuButton
                      class="relative inline-flex items-center rounded-md disabled:bg-indigo-400 text-sm  font-semibold bg-indigo-600 px-2 py-2 text-white  hover:bg-indigo-500 focus:z-10">
                      Export
                      <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                    </MenuButton>
                    <transition enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems
                        class="absolute right-0 bottom-full mb-2 w-56 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        style="z-index: 1050;">

                        <div class="py-1">
                          <MenuItem v-for="item in items" :key="item.name" v-slot="{ active }">
                          <button type="button" @click="item.action"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                            {{ item.name }}
                          </button>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>

                <button @click="closeDialog" type="button"
                  class="mt-3 mr-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  ref="cancelButtonRef">Cancel</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useThingsStore } from '@/stores/thingsStore';


const props = defineProps({
  open: {
    type: Boolean,
    default: true,
  },
});



const emit = defineEmits(['update:open']);

const thingsStore = useThingsStore();

//const baseUrl = `${window.location.protocol}//${window.location.host}`;

// Method to close dialog
function closeDialog() {
  emit('update:open', false);
}

const selectLabel = (action) => {
  selectedLabel.value = action;
};



const actions = [
  {
    title: 'Square Label',
    href: '#',
    qrCode: thingsStore.thing.qrLabels.labelSquare,
    qrCodeHeight: 200,
    description: "Download this compact square QR label for a seamless and versatile integration on any surface or item.",
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-gray-100',

  },
  {
    title: 'Landscape Label',
    href: '#',
    qrCode: thingsStore.thing.qrLabels.labelLandscape,
    qrCodeHeight: 120,
    description: "Opt for the landscape QR label to ensure maximum visibility and readability, perfect for wider items or spaces.",
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-gray-100',
  },
  {
    title: 'Plain Label',
    href: '#',
    qrCode: thingsStore.thing.qrLabels.labelPlain,
    qrCodeHeight: 120,
    description: "Grab the plain square QR code for ultimate customization freedom, allowing you to style and adapt it to fit any design requirement.",
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-gray-100',
  },
];

const selectedLabel = ref(actions[0]);

const printLabel = () => {
  if (!selectedLabel.value) {
    console.error("No label selected");
    return;
  }

  // Open a new window or iframe
  let printWindow = window.open('', '_blank');

  // Define print-specific CSS to ensure the image fits the page
  const printCSS = `
    <style>
      @media print {
        body {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }
        img {
          max-width: 100%;
          max-height: 100vh;
          object-fit: contain;
        }
      }
    </style>
  `;

  // Create an HTML structure with the print CSS and the image
  printWindow.document.write(`
    <html>
      <head>
        <title>Print QR Code</title>
        ${printCSS}
      </head>
      <body>
        <img src="${selectedLabel.value.qrCode}" onload="window.print();window.close();">
      </body>
    </html>
  `);

  // Close the document to trigger the image's onload event
  printWindow.document.close();
};


const downloadLabel = () => {

  if (!selectedLabel.value) {
    console.error("No label selected");
    return;
  }

  // Convert base64 to a blob
  const base64 = selectedLabel.value.qrCode;
  const base64Response = fetch(base64);

  const publicId = thingsStore.thing.publicId; // Access the publicId from your store or props

  base64Response.then(res => res.blob()).then(blob => {
    // Create a URL for the blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${publicId}-${selectedLabel.value.title.replace(/\s+/g, '-').toLowerCase()}.png`; // Naming the file with publicId included
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link); // Clean up
    URL.revokeObjectURL(blobUrl); // Free up the memory used by the blob
  });
};


const items = [
  { name: 'Print', action: printLabel },
  { name: 'Export PNG', action: downloadLabel },
  // { name: 'Export SVG', action: downloadLabel },
  // { name: 'Export PDF', action: downloadLabel },
  // { name: 'Export to Canva', action: downloadLabel },
]


</script>