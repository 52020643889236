// interceptors.js
import { getToken } from '@/auth';
import logger from '@/utils/logger.mjs';

export const setupAuthInterceptor = (httpClient) => {
  httpClient.interceptors.request.use(async (config) => {
    const token = await getToken();
    logger.debug(token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
};