<template>
    <div v-if="thingsStore.thing">
      <div v-if="!isReadOnly" class="sm:hidden h-full flex flex-col bg-white shadow-xl ">
      <div class="p-4">
        <button @click="goToList"
          class="rounded-md text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Close panel</span>
          Close
        </button>
      </div>
    </div>
    <div>
      <!-- Profile header -->
<div>
  <div class="block relative group hover:cursor-pointer z-1">
  <div v-if="!isReadOnly"
    class="absolute inset-0 flex items-center justify-center z-10 bg-gray-500 bg-opacity-60 opacity-0 group-hover:opacity-100 visibility-hidden group-hover:visibility-visible transition-opacity duration-300 ease-in-out">
    <ImageUploadComponent cropRatio="5:1" imgOnly="true" @uploaded="handleFileUpload"
      field="coverImageUrl"></ImageUploadComponent>
  </div>

  <img class="h-20 w-full object-cover sm:h-48"
    :src="thingsStore.thing.coverImageUrl" />
</div>


      </div>
      <!-- Grid layout for content below the header -->
      <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 grid grid-cols-12 gap-4">
        <!-- First Column: Smaller Image -->
        <div class="-mt-10 col-span-12 sm:col-span-2" style="z-index:5">


          <div class="block relative group hover:cursor-pointer z-1">
            <!-- File Uploader Component (hidden by default, shown on hover) -->
            <div v-if="!isReadOnly"
            class="absolute inset-0 flex items-center justify-center z-10 bg-gray-500 bg-opacity-60 opacity-0 group-hover:opacity-100 visibility-hidden group-hover:visibility-visible transition-opacity duration-300 ease-in-out">
                <ImageUploadComponent cropRatio="1:1" imgOnly="true" @uploaded="handleFileUpload"
                  field="imageUrl"></ImageUploadComponent>
            </div>

            <!-- Image -->
            <img class="h-32 w-32 sm:h-32 sm:w-32 p-2 rounded-md bg-white "
              :src="thingsStore.thing.imageUrl || '/assets/img-placeholder.jpg'" />
          </div>

        </div>


        <!-- Second Column: Text Fields and Span, spanning 2 columns -->
        <div class="col-span-8 sm:col-span-6 sm:mt-0">
          <div class="space-y-2 sm:mt-8">
            <EditableTextField :value="thingsStore.thing.name" fieldId="name" fieldPath="name"
              textClass="text-2xl font-bold text-gray-900 truncate" inputClass="text-2xl font-bold text-gray-900 truncate"
              @update-field="handleFieldUpdate" :isReadOnly="props.isReadOnly" placeholder="Enter thing name" />
            <EditableTextField :value="thingsStore.thing.location" fieldId="location" fieldPath="location"
              placeholder="Enter Thing Location" textClass="text-xs p-1" inputClass="text-xs p-1"
              @update-field="handleFieldUpdate" :isReadOnly="props.isReadOnly" />
            <!-- <span
              class="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
              Safety Equipment
            </span> -->

          </div>
        </div>

        <!-- third column -->
        <div v-if="!isReadOnly" class="col-span-12 sm:col-span-2 mt-8 mb-6 flex flex-col items-center">
          <div class="relative group hover:cursor-pointer w-full flex justify-center items-center">
            <div
              class="p-1 absolute inset-0 hidden group-hover:flex items-center justify-center rounded-md bg-gray-500 bg-opacity-60">
              <button @click="handlePrintLabels" type="button"
                class="inline-flex rounded-md bg-gray-300 px-3 py-2 text-center text-xs text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500">
                <PrinterIcon class="h-5 w-5 mr-2" />Print QR
              </button>
            </div>
            <qrcode-vue class="p-1" :value="qrcodeValue" :size="120" level="M"></qrcode-vue>
          </div>
        </div>

      </div>
    </div>


    <!-- Tabs -->
    <div class="mt-6 mx-auto sm:mt-2 2xl:mt-5 max-w-5xl">
      <div class="border-b border-gray-200 grid grid-cols-4 sm:grid-cols-12">

        <div class="col-span-2 sm:col-span-8">
          <nav class="flex space-x-8 -mb-px mx-auto max-w-5xl px-4 sm:px-6 lg:px-8" aria-label="Tabs">
            <div class="flex space-x-8">
              <a v-for="tab in tabs" :key="tab.name" :href="tab.href"
                :class="[tab.current ? 'border-pink-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium flex items-center']"
                :aria-current="tab.current ? 'page' : undefined">
                {{ tab.name }}
              </a>
            </div>
          </nav>
        </div>

        <div v-if="!isReadOnly"
          class="ml-5 col-span-2 sm:col-span-4 isolate inline-flex items-center rounded-md shadow-sm">
          <button type="button" @click="handlePrintLabels"
            class="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            <PrinterIcon class="h-5 w-5"></PrinterIcon>
          </button>
          <button type="button" @click="handlePreview"
            class="relative -ml-px inline-flex items-center bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            <EyeIcon class="h-5 w-5"></EyeIcon>
          </button>
          <button type="button" @click="handleCloneThing"
          class="relative -ml-px inline-flex items-center bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            <DocumentDuplicateIcon class="h-5 w-5"></DocumentDuplicateIcon>
          </button>
          <button type="button" @click="handleThingArchive"
            class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            <TrashIcon class="h-5 w-5"></TrashIcon>
          </button>
        </div>

      </div>
    </div>



    <!-- Description list -->
    <div class="mt-6 mx-auto max-w-5xl px-4 sm:px-8 lg:px-8">


      <dl class="grid grid-cols-12 gap-x-4 md:gap-x-6 gap-y-8">

        <!-- First Column -->
        <div class="col-span-12 sm:col-span-8 relative ">

          <div class="text-sm text-gray-900 md:pl-11 lg:pl-">
            <EditableWyswig @update-field="handleFieldUpdate" v-if="thingsStore.thing.data"
              @update-content="handleUpdateContent" :isReadOnly="props.isReadOnly" :data="thingsStore.thing.data"
              :id="thingsStore.thing.id" class="mb-8"/>
          </div>
          <div class="sm:absolute top-0 bottom-0 right-0 border-t sm:border-r border-gray-300 hidden sm:block"></div>
        </div>

        <!-- Second Column -->

        <div class="col-span-12 mt-2 sm:col-span-4 p-0 m-0 flex flex-col border-t sm:border-t-0 sm:pt-2">
          <div>
            <PeopleList v-if="!isReadOnly" @update-assigned-owner="handleSelectedUser" :isReadOnly="props.isReadOnly"
              :selectedUserId="thingsStore.thing.ownerId" fieldLabel="Owner" fieldId="fieldName" inputClass="inputClass"
              textClass="textClass" />


              <div v-for="field in thingsStore.thing.fields" :key="field.id" class="mb-2 w-full">
              <div>
                <dd class="mt-2 text-sm text-gray-900 w-full">
                  <MapComponent v-if="field.type == 'map'" :position="field.value" :fieldId="field.id" />
                  <EditableTextField v-if="field.type == 'text'" :isReadOnly="props.isReadOnly" :value="field.value"
                    :fieldPath="`fields.${field.id}.value`" :fieldLabel="field.label" :fieldId="field.id" textClass=""
                    inputClass="" @update-field="handleFieldUpdate" />
                </dd>
              </div>
            </div>
          </div>
          <!-- <div class="mt-12 pt-4 border-t border-gray-300">
            <dt class="text-sm font-medium text-gray-500">Activity</dt>
            <dd class="mt-1 ml-2 pt-4 text-sm text-gray-900">
               <CommentComponent></CommentComponent>
            </dd>
          </div> -->
        </div>
      </dl>

    </div>
    <PrintComponent v-if="thingsStore.thing.qrLabels" :open="isDialogOpen" @update:open="isDialogOpen = $event"></PrintComponent>
    <GpsLocationHandler @gps-location-update="handleGpsUpdate"></GpsLocationHandler>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useThingsStore } from '@/stores/thingsStore';
import { EyeIcon, PrinterIcon, DocumentDuplicateIcon} from '@heroicons/vue/24/outline'

import ImageUploadComponent from '@/components/ImageUploadComponent.vue';

import EditableWyswig from '@/components/EditableWyswig.vue';
// import CommentComponent from '@/components/CommentComponent.vue';
import MapComponent from '@/components/MapComponent.vue';

import EditableTextField from '@/components/InputFieldComponents/EditableTextField.vue';
import PeopleList from '@/components/PeopleList.vue';
import PrintComponent from '@/components/PrintComponent.vue'

import GpsLocationHandler from '@/components/GpsLocationHandler.vue';

import QrcodeVue from 'qrcode.vue';
import { useRouter } from 'vue-router';


import logger from '@/utils/logger.mjs'
import { TrashIcon } from '@heroicons/vue/24/solid';
import { useDialogStore } from '@/stores/dialogStore';
import { usePeopleStore } from '@/stores/peopleStore';
import { auth0 } from '@/auth';

const emit = defineEmits(['close']);

const isDialogOpen = ref(false);
const router = useRouter();
const dialogStore = useDialogStore();
const thingsStore = useThingsStore();
const peopleStore = usePeopleStore();


const qrcodeValue = computed(() => {
  if (thingsStore.thing && thingsStore.thing.id) {
    const route = router.resolve({ path: `/pt/${thingsStore.thing.id}` });
    return window.location.origin + route.href;
  }
  return '';
});


onMounted(() => {

  if (auth0.user.value)
    peopleStore.fetchPeople();
});

async function handlePrintLabels() {
  await thingsStore.fetchThingyLabels();
  openLabelDialog()
}

async function handleGpsUpdate(data){
  console.log('handling gps update');
  await thingsStore.updateGPSLocation(data);

}

async function navigateToThingDetails(newThingId) {

await thingsStore.fetchThingById(newThingId);
router.push({
  name: 'things-details',
  params: { thingId:newThingId }
});
}

const handleCloneThing = async () => {

  dialogStore.openDialog({
    title: 'Confirm Cloning',
    message: 'Are you sure you want to clone this Thingy?',
    onConfirm: async () => {
      logger.info('Action confirmed');
      const thingId = thingsStore.thing.id;
      if (thingId) {
        const newThingId = await thingsStore.cloneThing(thingsStore.thing.id)      
        navigateToThingDetails(newThingId);
      }
    },
    onCancel: () => {
      logger.info('Action canceled');
      // Handle the cancel action
    },
  });
};

function handlePreview() {
  const thingId = thingsStore.thing.id;
  if (thingId) {
    const previewUrl = `/pt/${thingId}`; // Construct the URL for the preview
    window.open(previewUrl, '_blank'); // Open the preview URL in a new tab
  } else {
    console.error('Thing ID is missing for preview.');
  }
}

const openLabelDialog = () => {
  isDialogOpen.value = true;

}


const props = defineProps({
  isReadOnly: Boolean,
});


// Handle the update event from EditableTextField
const handleFieldUpdate = (field) => {

  if (field && field.id == 'map') {
    dialogStore.openDialog({
      title: "Location Successfully Updated",
      message: "New location captured! Check it on your dashboard.",
      cancelButton:'',
      confirmButton:'OK'
    });

    thingsStore.updateGPSLocation(field);
  }
  logger.info('updating field');
  if (field && field.id) {
    logger.info(field);
    thingsStore.updateField(field);
  } else {
    console.error('Invalid field data for update');
  }
};



const handleUpdateContent = (data) => {
  logger.info('updating content');
  const thingId = data.thingId;

  const field = {};
  field.path = 'data';
  field.value = data.content;


  thingsStore.updateField(field, thingId);
};

const handleSelectedUser = (user) => {
  logger.info('updating user');

  const field = {};
  field.path = 'ownerId';
  field.value = user.id;

  thingsStore.updateField(field);
};

const handleFileUpload = (event) => {
  logger.info('execute form updates for upload');

  const field = {};
  field.path = event.path;
  field.value = event.value;
  thingsStore.updateField(field);
};

const goToList = () => {
  emit('close');
};

const tabs = [
  { name: 'Content', href: '#', current: true, disabled: false },
  // { name: 'Internal Content', href: '#', current: false, disabled:true},
  // { name: '+', href: '#', current: false, disabled: true },
  //  { name: 'Permissions', href: '#', current: false },
]


async function handleThingArchive() {
  dialogStore.openDialog({
    title: 'Confirm Deletion',
    message: 'Are you sure you want to delete the thing? This action will permanently disable the associated QR code.',
    onConfirm: async () => {
      logger.info('Action confirmed');
      const thingId = thingsStore.thing.id;
      if (thingId) {
        thingsStore.archive();
      }
      // Perform the confirm action
    },
    onCancel: () => {
      logger.info('Action canceled');
      // Handle the cancel action
    },
  });
}

</script>