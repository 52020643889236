<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="mt-10 flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-white">Configure Smart Button
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button"
                          class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          @click="open = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <div>
                      <label for="project-name" class="block text-sm font-medium leading-6 text-gray-900">Button
                        Text</label>
                      <div class="mt-1 mb-4">
                        <input type="text" name="project-name" id="project-name" value="Click Me"
                          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                      </div>
                    </div>
                    <div>
                      <Listbox as="div" v-model="selected">
                        <label for="project-name" class="block text-sm font-medium leading-6 text-gray-900">Button Action</label>
                        <div class="relative mt-1 mb-4">
                          <ListboxButton
                            class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <span class="block truncate">{{ selected.name }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <ListboxOptions
                              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              <ListboxOption as="template" v-for="person in people" :key="person.id" :value="person"
                                v-slot="{ active, selected }">
                                <li
                                  :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                    person.name }}</span>

                                  <span v-if="selected"
                                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>

                    <div class="mt-3 rounded-md bg-blue-50 p-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between mb-3">
                          <p class="text-sm text-blue-700">Smart buttons are hxaskldjasjdoais</p>

                        </div>
                      </div>
                    </div>



                  </div>


                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

  
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

const open = ref(false); // Initially, the dialog is not shown

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const people = [
  // { id: 1, name: 'Submit Data' },
  { id: 2, name: 'Call Phone' },
  { id: 3, name: 'Send SMS' },
  { id: 4, name: 'Send Email' },
  // { id: 5, name: 'Start MS Teams Call' },
  // { id: 6, name: 'Start Zoom Call' },
  { id: 7, name: 'Collect GPS Location' },
  // { id: 9, name: 'RSVP Yes' },
  // { id: 10, name: 'RSVP No' },
]

const selected = ref(people[0])
// Function to handle the custom event
function handleEditorButtonOpen() {
  open.value = true; // Open the dialog
}

onMounted(() => {
  // Add event listener when the component is mounted
  window.addEventListener('editorjs-button-editor:open', handleEditorButtonOpen);
});

onBeforeUnmount(() => {
  // Remove event listener when the component is about to unmount
  window.removeEventListener('editorjs-button-editor:open', handleEditorButtonOpen);
});
</script>