<template>
  <div class="px-6 pb-4 pt-6">
    <div class="flex justify-between items-center">
      <div>
        <span class="text-base font-semibold leading-6 text-gray-900">My Things</span>
        <p class="mt-1 text-sm text-gray-500">Search list of {{ totalThings }} things</p>
      </div>
      
    </div>
    <form class="mt-6 flex gap-4" @submit.prevent="onFormSubmit">
      <div class="flex-1 min-w-0">
        <label for="search" class="sr-only">Search</label>
        <div class="relative rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input type="search" name="search" id="search" v-model="searchQuery"
            class="block w-full rounded-md border-gray-300 py-1.5 pl-10 text-sm placeholder-gray-400 focus:border-pink-500 focus:ring-pink-500"
            placeholder="Search" />
        </div>
      </div>
    </form>
  </div>

  <!-- Things list -->
  <nav class="overflow-y-auto min-h-0 flex-1" aria-label="Things">
    <div v-for="(items, group) in groupedJson" :key="group" class="relative">
      <div
        class="sticky top-0 z-10 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500 border-t border-b border-gray-200">
        <span>{{ group }}</span>
      </div>
      <ul role="list" class="relative z-0 divide-y divide-gray-200">
        <li v-for="(thing, id) in items" :key="id">
          <div @click="handleSelectThing(thing)"
            class="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
            <div class="flex-shrink-0">
              <div class="h-10 w-10 overflow-hidden rounded-md p-1 bg-white">
                <img class="w-full h-full object-cover rounded-sm"
                  :src="thing.imageUrl || '/assets/img-placeholder.jpg'" />
              </div>


            </div>
            <div class="min-w-0 flex-1">
              <a href="#" class="focus:outline-none">
                <!-- Extend touch target to entire panel -->
                <span class="absolute inset-0" aria-hidden="true"></span>
                <p class="text-sm font-medium text-gray-900">{{ thing.name || 'New Thing' }}</p>
                <p class="text-xs py-0 truncate text-gray-500">{{ thing.location }}</p>
                <!-- <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Safety Equipment</span> -->
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script setup>
import { ref, computed, watch } from 'vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['selectedThingId']);
const thingsStore = useThingsStore(); // Use the things store
import { useThingsStore } from '@/stores/thingsStore';

const searchQuery = ref(thingsStore.getSearchQuery()); 

const totalThings = computed(() => {
  return thingsStore.things.length; // Use the store's state
});

const onFormSubmit = () => {
};

watch(searchQuery, (newQuery) => {
  thingsStore.setSearchQuery(newQuery);
});


const groupedJson = computed(() => {
  const result = {};
  thingsStore.filteredThings.forEach(thing => {
    const location = thing.location;
    if (!result[location]) {
      result[location] = [];
    }
    result[location].push(thing);
  });
  return result;
});


const handleSelectThing = (thing) => {
  emit('selectedThingId', thing.id);
};



</script>