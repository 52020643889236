<template>
    <div>
      <PageLoader></PageLoader>
    </div>
  </template>

  <script setup>

import { onMounted } from 'vue';
import { logout } from '@/auth';
import PageLoader from '@/components/PageLoader.vue';

  onMounted(() => {
  });
  logout({ logoutParams: { returnTo: 'https://getthingy.io' } });

  </script>