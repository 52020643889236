// src/composables/useUserAnalytics.js
import mixpanel from 'mixpanel-browser';
import logger from '@/utils/logger.mjs';

mixpanel.init('102ce5af2360e10f1a0ec2d934261437');

const userAnalytics = {
  // Wrapper for Mixpanel's track method
  track(event, properties) {
    mixpanel.track(event, properties);
  },
  
  // Wrapper for Mixpanel's identify method
  identify(user) {
    logger.log('identifying user');
    logger.log(user);
    mixpanel.identify(user.sub);
    mixpanel.people.set({
      $first_name: user.given_name,
      $last_name: user.family_name,
      $email: user.email,
      $avatar: user.picture
  });
  },
  
  // Wrapper for setting user properties
  setUserProperties(properties) {
    mixpanel.people.set(properties);
  },
  
  trackPageView(path) {
    mixpanel.track('Page View', { path });
  },
  
  // Add more methods as needed to abstract Mixpanel functionality
};

export function useUserAnalytics() {
  return userAnalytics;
}
