<script setup>
import { onMounted, onUnmounted } from 'vue';
const emit = defineEmits(['gps-location-update']);

// Function to capture GPS location and dispatch 'update-field' event
const captureAndDispatchGpsLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const detail = {
                    // Assuming 'id' and 'path' are predefined or obtained from some other part of your application
                    id: 'map', // Unique identifier for your GPS data field
                    latitude: position.coords.latitude,
                    longitude:position.coords.longitude,
                };

                emit('gps-location-update', detail);
                document.dispatchEvent(new CustomEvent('gps-location-finish-loading'));

            },
            (error) => {
                console.error('Error obtaining location', error);
                alert('Unable to retrieve your location');
                document.dispatchEvent(new CustomEvent('gps-location-finish-loading'));
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            }
        );
    } else {
        alert('Geolocation is not supported by this browser.');
    }
};

// Setup event listener on component mount and cleanup on unmount
onMounted(() => {
    document.addEventListener('request-gps-location', captureAndDispatchGpsLocation);
});

onUnmounted(() => {
    document.removeEventListener('request-gps-location', captureAndDispatchGpsLocation);
});
</script>
