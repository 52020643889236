// stores/dialogStore.js
import { defineStore } from 'pinia';
import logger from '@/utils/logger.mjs';

export const useDialogStore = defineStore('dialog', {
    state: () => ({
      isOpen: false,
      title: '',
      message: '',
      confirmButton: 'Confirm',
      cancelButton: 'Cancel',
      onConfirm: null,
      onCancel: null,
    }),
    actions: {
      openDialog({ title, message, confirmButton = 'Confirm', cancelButton = 'Cancel', onConfirm, onCancel }) {
        this.title = title;
        this.message = message;
        this.confirmButton = confirmButton;
        this.cancelButton = cancelButton;
        this.onConfirm = onConfirm;
        this.onCancel = onCancel;
        this.isOpen = true;
      },
      confirmDialog() {
        if (this.onConfirm) this.onConfirm();
        this.closeDialog();
      },
      cancelDialog() {
        if (this.onCancel) this.onCancel();
        this.closeDialog();
      },
      closeDialog() {
        this.isOpen = false;
        this.onConfirm = null;
        this.onCancel = null;
      },
      initialize() {
        logger.info('dialog ok');
      }
    }
  });
  
